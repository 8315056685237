// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user', // Update this to 'user'
  initialState: {
    userData: null,
    userExists: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload.userData;
      state.userExists = action.payload.userExists;
    },
    resetUser: (state) => {
      state.userData = null;
      state.userExists = false;
    },
  },
});

export const { setUserData, resetUser } = userSlice.actions;

export const selectUserData = (state) => state.user.userData; // Update this to state.user.userData
export const selectUserExists = (state) => state.user.userExists; // Update this to state.user.userExists

export default userSlice.reducer;
