import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { PiFilePdfDuotone } from "react-icons/pi";
import { FaEdit, FaPlus, FaTrash, FaUpload, FaTimes } from 'react-icons/fa';
import "./PdfAntiragging.css";
import { Base_Url } from './config';
import { MdClose } from "react-icons/md";
import { Document, Page,pdfjs } from 'react-pdf';
import axios from 'axios';
import { useSelector } from 'react-redux';

Modal.setAppElement('#root');

function Pdf() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState('');
  const [selectedPdf, setSelectedPdf] = useState({});
  const [pdfPath, setPdfPath] = useState('');
  const [advisory, setAdvisory] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [editImagePreview, setEditImagePreview] = useState(null);
  const [newPdf, setNewPdf] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [editPdf, setEditPdf] = useState(null);
  const [newImageName, setNewImageName] = useState('');
  const [newPdfName, setNewPdfName] = useState('');
  const [editImageName, setEditImageName] = useState('');
  const [editPdfName, setEditPdfName] = useState('');

  const userExists = useSelector((state) => state.user.userExists);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const openModal = (pdfPath) => {
    setPdfFile(pdfPath);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setPdfFile('');
  };

  const openEditModal = (e, pdf) => {
    e.stopPropagation();
    setSelectedPdf(pdf);
    setPdfPath(pdf.path);
    setEditImage(null);
    setEditPdf(null);
    setEditImagePreview(pdf.src); // Set existing image as preview on modal open
    setEditImageName(''); // Reset edit image name
    setEditPdfName(''); // Reset edit PDF name
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedPdf({});
  };

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
    setEditImage(file); // Save the file object in state
    setEditImagePreview(URL.createObjectURL(file)); // Generate a preview URL
    setEditImageName(file.name); // Set the edit image name
  };

  const handleEditPdfChange = (e) => {
    const file = e.target.files[0];
    setEditPdf(file); // Save the file object in state
    setEditPdfName(file.name); // Set the edit PDF name
  };

  const handleSaveChanges = () => {
    const formData = new FormData();
    if (editImage) formData.append('image', editImage);
    if (editPdf) formData.append('pdf', editPdf);

    axios.put(`${Base_Url}/update-advisory/${selectedPdf.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      closeEditModal();
      fetchAdvisory();
    })
    .catch(error => {
      console.error('Error updating advisory:', error);
    });
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setNewImage(null);
    setNewPdf(null);
    setNewImageName(''); // Reset new image name
    setNewPdfName(''); // Reset new PDF name
  };

  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setNewImageName(file.name); // Set the new image name
  };

  const handleNewPdfChange = (e) => {
    const file = e.target.files[0];
    setNewPdf(file);
    setNewPdfName(file.name); // Set the new PDF name
  };

  const handleAddNew = () => {
    const formData = new FormData();
    if (newImage) formData.append('image', newImage);
    if (newPdf) formData.append('pdf', newPdf);

    fetch(`${Base_Url}/create-advisory`, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.ok) {
          closeAddModal();
          setNewImage(null);
          setNewPdf(null);
          fetchAdvisory()
        } else {
          console.error('Failed to upload PDF and image');
        }
      })
      .catch(error => {
        console.error('Error uploading PDF and image:', error);
      });
  };

  const fetchAdvisory = async () => {
    try {
      const response = await axios.get(`${Base_Url}/advisories`);
      setAdvisory(response.data)
    } catch (error) {
      console.error('Error fetching advisories:', error);
    }
  };

  useEffect(() => {
    fetchAdvisory();
  }, []);

  const handleDeleteAdvisory = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this advisory?');
    if (confirmed) {
      try {
        await axios.delete(`${Base_Url}/advisory/${id}`);
        fetchAdvisory();
      } catch (error) {
        console.error('Error deleting advisory:', error);
      }
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="pdf mt-5">
      <hr />
      <div className="h1 mb-4 text-center text-dark ">
        <span className='headingSpan' style={{ color: "#c21717",fontSize:"2rem" }}>Advisory issued by Joint Secretary, UGC regarding the revised procedure of Anti-Ragging Undertaking & Compliance Submission</span>{" "}
        {
          userExists ?  <span className='ml-2'>
          <FaPlus color='#c21717' className='ms-2 mb-1' onClick={openAddModal} style={{ cursor: 'pointer' }} />
        </span> :""
        }
      <hr />
        {/* <span style={{ color: "#c21717" }}>@</span> <span>ADVISORY</span> */}
        

        <p></p>
      </div>

      <div className="inception-images mb-5" style={{ height: "650px", cursor: "pointer" }}>
        {advisory && advisory.length > 0 ? (
          advisory.map((elem) => (
            <div key={elem.id} className="image-container" onClick={() => openModal(`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`)}>
              <iframe
                src={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
                width="100%"
                height="100%"
                title={`PDF Viewer - ${elem.id}`}
              />
              {userExists && (
                <div className="action-buttons">
                  {/* <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id })}> */}
                  <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id , path:`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`})}>
                    <FaEdit />
                  </button>
                  <button className="delete-button" onClick={(e) => { e.stopPropagation(); handleDeleteAdvisory(elem.id) }}>
                    <FaTrash />
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="no-data-container">
            <div className="no-data-message">
              To be announced soon...
            </div>
          </div>
        )}
      </div>

   {/* <div>
    {advisory && advisory.map((elem) => (
      <Document
        key={elem.id}  
        file={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
        error="Error loading PDF"
      >
        <Page pageNumber={1} />
      </Document>
    ))}
  </div> */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel="PDF Modal"
        className="pdf-modal dark-mode"
        overlayClassName="pdf-modal-overlay dark-overlay"
      >
        <button onClick={closeModal} className="close-button dark-mode">Close</button>
        {pdfFile && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </Modal>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel="Add PDF Modal"
        className="add-modal-container"
        overlayClassName="add-modal-overlay"
      >
        <div className="add-modal">
          <div className="add-modal-header">
            <h2>Add New PDF</h2>
            <button onClick={closeAddModal} className="close-button">
              <MdClose />
            </button>
          </div>
          <div className="add-modal-body">
            {/* <div className="file-input">
              <label htmlFor="image-upload" className="file-input-label">
                <FaUpload className="upload-icon" />
                {newImageName ? newImageName : "Upload Image"}
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                onChange={handleNewImageChange}
              />
            </div> */}
            <div className="file-input">
              <label htmlFor="pdf-upload" className="file-input-label">
                <FaUpload className="upload-icon" />
                {newPdfName ? newPdfName : "Upload PDF"}
              </label>
              <input
                id="pdf-upload"
                type="file"
                accept="application/pdf"
                onChange={handleNewPdfChange}
              />
            </div>
          </div>
          <div className="add-modal-footer">
            <button onClick={handleAddNew} className="add-button">
              Add
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Advisory"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <h2>Edit Advisory</h2>
          <button className="close-btn" onClick={closeEditModal}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-body">
          {/* <div className="file-info">
            <span style={{fontWeight:"bold"}}>Editing File:</span>
            <span className="file-name">{selectedPdf?.path?.split('/').pop()}</span>
          </div> */}
          {editImagePreview && (
            <div className="edit-image-preview">
              <img className='mb-4' style={{width:"400px"}} src={editImagePreview} alt="Preview" />
            </div>
          )}
          {/* <div className="file-input">
            <label htmlFor="image-upload">
              <FaUpload className="upload-icon" />
              Upload New Image
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                onChange={handleEditImageChange}
              />
            </label>
            {editImageName && <span className="file-name">{editImageName}</span>}
          </div> */}
          <div className="file-input">
            <label htmlFor="pdf-upload">
              <FaUpload className="upload-icon" />
              Upload New PDF
              <input
                id="pdf-upload"
                type="file"
                accept="application/pdf"
                onChange={handleEditPdfChange}
              />
            </label>
            {editPdfName && <span className="file-name">{editPdfName}</span>}
          </div>
          <div className="pdf-link">
            <a href={selectedPdf.path} target="_blank" rel="noopener noreferrer">View Old PDF</a>
          </div>
          <button className="save-btn" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Pdf;
// import React, { useEffect, useState } from 'react';
// import Modal from 'react-modal';
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { PiFilePdfDuotone } from "react-icons/pi";
// import { FaEdit, FaPlus, FaTrash, FaUpload, FaTimes } from 'react-icons/fa';
// import "./PdfAntiragging.css";
// import { Base_Url } from './config';
// import { MdClose } from "react-icons/md";
// import { Document, Page,pdfjs } from 'react-pdf';
// import axios from 'axios';
// import { useSelector } from 'react-redux';

// Modal.setAppElement('#root');

// function Pdf() {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [editModalIsOpen, setEditModalIsOpen] = useState(false);
//   const [addModalIsOpen, setAddModalIsOpen] = useState(false);
//   const [pdfFile, setPdfFile] = useState('');
//   const [selectedPdf, setSelectedPdf] = useState({});
//   const [pdfPath, setPdfPath] = useState('');
//   const [advisory, setAdvisory] = useState([]);
//   const [newImage, setNewImage] = useState(null);
//   const [editImagePreview, setEditImagePreview] = useState(null);
//   const [newPdf, setNewPdf] = useState(null);
//   const [editImage, setEditImage] = useState(null);
//   const [editPdf, setEditPdf] = useState(null);
//   const [newImageName, setNewImageName] = useState('');
//   const [newPdfName, setNewPdfName] = useState('');
//   const [editImageName, setEditImageName] = useState('');
//   const [editPdfName, setEditPdfName] = useState('');

//   const userExists = useSelector((state) => state.user.userExists);
//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
//   const openModal = (pdfPath) => {
//     setPdfFile(pdfPath);
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//     setPdfFile('');
//   };

//   const openEditModal = (e, pdf) => {
//     e.stopPropagation();
//     setSelectedPdf(pdf);
//     setPdfPath(pdf.path);
//     setEditImage(null);
//     setEditPdf(null);
//     setEditImagePreview(pdf.src); // Set existing image as preview on modal open
//     setEditImageName(''); // Reset edit image name
//     setEditPdfName(''); // Reset edit PDF name
//     setEditModalIsOpen(true);
//   };

//   const closeEditModal = () => {
//     setEditModalIsOpen(false);
//     setSelectedPdf({});
//   };

//   const handleEditImageChange = (e) => {
//     const file = e.target.files[0];
//     setEditImage(file); // Save the file object in state
//     setEditImagePreview(URL.createObjectURL(file)); // Generate a preview URL
//     setEditImageName(file.name); // Set the edit image name
//   };

//   const handleEditPdfChange = (e) => {
//     const file = e.target.files[0];
//     setEditPdf(file); // Save the file object in state
//     setEditPdfName(file.name); // Set the edit PDF name
//   };

//   const handleSaveChanges = () => {
//     const formData = new FormData();
//     if (editImage) formData.append('image', editImage);
//     if (editPdf) formData.append('pdf', editPdf);

//     axios.put(`${Base_Url}/update-advisory/${selectedPdf.id}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then(response => {
//       closeEditModal();
//       fetchAdvisory();
//     })
//     .catch(error => {
//       console.error('Error updating advisory:', error);
//     });
//   };

//   const openAddModal = () => {
//     setAddModalIsOpen(true);
//   };

//   const closeAddModal = () => {
//     setAddModalIsOpen(false);
//     setNewImage(null);
//     setNewPdf(null);
//     setNewImageName(''); // Reset new image name
//     setNewPdfName(''); // Reset new PDF name
//   };

//   const handleNewImageChange = (e) => {
//     const file = e.target.files[0];
//     setNewImage(file);
//     setNewImageName(file.name); // Set the new image name
//   };

//   const handleNewPdfChange = (e) => {
//     const file = e.target.files[0];
//     setNewPdf(file);
//     setNewPdfName(file.name); // Set the new PDF name
//   };

//   const handleAddNew = () => {
//     const formData = new FormData();
//     if (newImage) formData.append('image', newImage);
//     if (newPdf) formData.append('pdf', newPdf);

//     fetch(`${Base_Url}/create-advisory`, {
//       method: 'POST',
//       body: formData
//     })
//       .then(response => {
//         if (response.ok) {
//           closeAddModal();
//           setNewImage(null);
//           setNewPdf(null);
//           fetchAdvisory()
//         } else {
//           console.error('Failed to upload PDF and image');
//         }
//       })
//       .catch(error => {
//         console.error('Error uploading PDF and image:', error);
//       });
//   };

//   const fetchAdvisory = async () => {
//     try {
//       const response = await axios.get(`${Base_Url}/advisories`);
//       setAdvisory(response.data)
//     } catch (error) {
//       console.error('Error fetching advisories:', error);
//     }
//   };

//   useEffect(() => {
//     fetchAdvisory();
//   }, []);

//   const handleDeleteAdvisory = async (id) => {
//     const confirmed = window.confirm('Are you sure you want to delete this advisory?');
//     if (confirmed) {
//       try {
//         await axios.delete(`${Base_Url}/advisory/${id}`);
//         fetchAdvisory();
//       } catch (error) {
//         console.error('Error deleting advisory:', error);
//       }
//     }
//   };

//   const defaultLayoutPluginInstance = defaultLayoutPlugin();

//   return (
//     <div className="pdf mt-5">
//       <hr />
//       <div className="h1 mb-4 text-center text-dark ">
//         <span className='headingSpan' style={{ color: "#c21717",fontSize:"2rem" }}>Advisory issued by Joint Secretary, UGC regarding the revised procedure of Anti-Ragging Undertaking & Compliance Submission</span>{" "}
//         {
//           userExists ?  <span className='ml-2'>
//           <FaPlus color='#c21717' className='ms-2 mb-1' onClick={openAddModal} style={{ cursor: 'pointer' }} />
//         </span> :""
//         }
//       <hr />
//         {/* <span style={{ color: "#c21717" }}>@</span> <span>ADVISORY</span> */}
        

//         <p></p>
//       </div>

//       <div className="inception-images mb-5" style={{ height: "650px", cursor: "pointer" }}>
//         {
//           advisory && advisory.map((elem) => (
//             <div key={elem.id} className="image-container" onClick={() => openModal(`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`)}>
//               <img className='img' style={{ cursor: "pointer" }} src={`${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`} alt="Say no To Ragging..." />
//               <div className="icon-overlay" style={{ cursor: "pointer" }}>
//                 <PiFilePdfDuotone />
//               </div>
//               {
//                 userExists ? <div className="action-buttons">
//                 <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id, src: `${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`, path: `${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}` })}><FaEdit /></button>
//                 <button className="delete-button" onClick={(e) => {e.stopPropagation(); handleDeleteAdvisory(elem.id)}}><FaTrash /></button>
//               </div> :""
//               }

//             </div>
//           ))
//         }
//       </div>
//    {/* <div>
//     {advisory && advisory.map((elem) => (
//       <Document
//         key={elem.id}  
//         file={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
//         error="Error loading PDF"
//       >
//         <Page pageNumber={1} />
//       </Document>
//     ))}
//   </div> */}

//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="PDF Modal"
//         className="pdf-modal dark-mode"
//         overlayClassName="pdf-modal-overlay dark-overlay"
//       >
//         <button onClick={closeModal} className="close-button dark-mode">Close</button>
//         {pdfFile && (
//           <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
//             <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
//           </Worker>
//         )}
//       </Modal>

//       <Modal
//         isOpen={addModalIsOpen}
//         onRequestClose={closeAddModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="Add PDF Modal"
//         className="add-modal-container"
//         overlayClassName="add-modal-overlay"
//       >
//         <div className="add-modal">
//           <div className="add-modal-header">
//             <h2>Add New PDF</h2>
//             <button onClick={closeAddModal} className="close-button">
//               <MdClose />
//             </button>
//           </div>
//           <div className="add-modal-body">
//             <div className="file-input">
//               <label htmlFor="image-upload" className="file-input-label">
//                 <FaUpload className="upload-icon" />
//                 {newImageName ? newImageName : "Upload Image"}
//               </label>
//               <input
//                 id="image-upload"
//                 type="file"
//                 accept="image/*"
//                 onChange={handleNewImageChange}
//               />
//             </div>
//             <div className="file-input">
//               <label htmlFor="pdf-upload" className="file-input-label">
//                 <FaUpload className="upload-icon" />
//                 {newPdfName ? newPdfName : "Upload PDF"}
//               </label>
//               <input
//                 id="pdf-upload"
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleNewPdfChange}
//               />
//             </div>
//           </div>
//           <div className="add-modal-footer">
//             <button onClick={handleAddNew} className="add-button">
//               Add
//             </button>
//           </div>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={editModalIsOpen}
//         onRequestClose={closeEditModal}
//         contentLabel="Edit Advisory"
//         className="modal-content"
//         overlayClassName="modal-overlay"
//       >
//         <div className="modal-header">
//           <h2>Edit Advisory</h2>
//           <button className="close-btn" onClick={closeEditModal}>
//             <FaTimes />
//           </button>
//         </div>
//         <div className="modal-body">
//           {/* <div className="file-info">
//             <span style={{fontWeight:"bold"}}>Editing File:</span>
//             <span className="file-name">{selectedPdf?.path?.split('/').pop()}</span>
//           </div> */}
//           {editImagePreview && (
//             <div className="edit-image-preview">
//               <img className='mb-4' style={{width:"400px"}} src={editImagePreview} alt="Preview" />
//             </div>
//           )}
//           <div className="file-input">
//             <label htmlFor="image-upload">
//               <FaUpload className="upload-icon" />
//               Upload New Image
//               <input
//                 id="image-upload"
//                 type="file"
//                 accept="image/*"
//                 onChange={handleEditImageChange}
//               />
//             </label>
//             {editImageName && <span className="file-name">{editImageName}</span>}
//           </div>
//           <div className="file-input">
//             <label htmlFor="pdf-upload">
//               <FaUpload className="upload-icon" />
//               Upload New PDF
//               <input
//                 id="pdf-upload"
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleEditPdfChange}
//               />
//             </label>
//             {editPdfName && <span className="file-name">{editPdfName}</span>}
//           </div>
//           <div className="pdf-link">
//             <a href={selectedPdf.path} target="_blank" rel="noopener noreferrer">View Old PDF</a>
//           </div>
//           <button className="save-btn" onClick={handleSaveChanges}>
//             Save Changes
//           </button>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Pdf;

// import React, { useEffect, useState } from 'react';
// import Modal from 'react-modal';
// import { FaEdit, FaPlus, FaTrash, FaUpload, FaTimes } from 'react-icons/fa';
// import "./PdfAntiragging.css";
// import { Base_Url } from './config';
// import { MdClose } from "react-icons/md";
// import { Document, Page, pdfjs, getDocument } from 'react-pdf';
// import axios from 'axios';
// // import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import { useSelector } from 'react-redux';


// Modal.setAppElement('#root');

// // pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


// function Pdf() {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [editModalIsOpen, setEditModalIsOpen] = useState(false);
//   const [addModalIsOpen, setAddModalIsOpen] = useState(false);
//   const [selectedPdf, setSelectedPdf] = useState({});
//   const [advisory, setAdvisory] = useState([]);
//   const [newImage, setNewImage] = useState(null);
//   const [editImagePreview, setEditImagePreview] = useState(null);
//   const [newPdf, setNewPdf] = useState(null);
//   const [editImage, setEditImage] = useState(null);
//   const [editPdf, setEditPdf] = useState(null);
//   const [newImageName, setNewImageName] = useState('');
//   const [newPdfName, setNewPdfName] = useState('');
//   const [editImageName, setEditImageName] = useState('');
//   const [editPdfName, setEditPdfName] = useState('');

//   const userExists = useSelector((state) => state.user.userExists);

//   useEffect(() => {
//     fetchAdvisory();
//   }, []);

//   const openEditModal = (e, pdf) => {
//     e.stopPropagation();
//     setSelectedPdf(pdf);
//     setEditImage(null);
//     setEditPdf(null);
//     setEditImagePreview(pdf.src);
//     setEditImageName('');
//     setEditPdfName('');
//     setEditModalIsOpen(true);
//   };

//   const closeEditModal = () => {
//     setEditModalIsOpen(false);
//     setSelectedPdf({});
//   };

//   const handleEditImageChange = (e) => {
//     const file = e.target.files[0];
//     setEditImage(file);
//     setEditImagePreview(URL.createObjectURL(file));
//     setEditImageName(file.name);
//   };

//   const handleEditPdfChange = (e) => {
//     const file = e.target.files[0];
//     setEditPdf(file);
//     setEditPdfName(file.name);
//   };

//   const handleSaveChanges = () => {
//     const formData = new FormData();
//     if (editImage) formData.append('image', editImage);
//     if (editPdf) formData.append('pdf', editPdf);

//     axios.put(`${Base_Url}/update-advisory/${selectedPdf.id}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then(response => {
//       closeEditModal();
//       fetchAdvisory();
//     })
//     .catch(error => {
//       console.error('Error updating advisory:', error);
//     });
//   };

//   const openAddModal = () => {
//     setAddModalIsOpen(true);
//   };

//   const closeAddModal = () => {
//     setAddModalIsOpen(false);
//     setNewImage(null);
//     setNewPdf(null);
//     setNewImageName('');
//     setNewPdfName('');
//   };

//   const handleNewImageChange = (e) => {
//     const file = e.target.files[0];
//     setNewImage(file);
//     setNewImageName(file.name);
//   };

//   const handleNewPdfChange = (e) => {
//     const file = e.target.files[0];
//     setNewPdf(file);
//     setNewPdfName(file.name);
//   };

//   const handleAddNew = () => {
//     const formData = new FormData();
//     if (newImage) formData.append('image', newImage);
//     if (newPdf) formData.append('pdf', newPdf);

//     fetch(`${Base_Url}/create-advisory`, {
//       method: 'POST',
//       body: formData
//     })
//       .then(response => {
//         if (response.ok) {
//           closeAddModal();
//           setNewImage(null);
//           setNewPdf(null);
//           fetchAdvisory();
//         } else {
//           console.error('Failed to upload PDF and image');
//         }
//       })
//       .catch(error => {
//         console.error('Error uploading PDF and image:', error);
//       });
//   };

//   const fetchAdvisory = async () => {
//     try {
//       const response = await axios.get(`${Base_Url}/advisories`);
//       setAdvisory(response.data);
//     } catch (error) {
//       console.error('Error fetching advisories:', error);
//     }
//   };

//   const handleDeleteAdvisory = async (id) => {
//     const confirmed = window.confirm('Are you sure you want to delete this advisory?');
//     if (confirmed) {
//       try {
//         await axios.delete(`${Base_Url}/advisory/${id}`);
//         fetchAdvisory();
//       } catch (error) {
//         console.error('Error deleting advisory:', error);
//       }
//     }
//   };

//   return (
//     <div className="pdf">
//       <div className="h1 mb-4 text-center text-dark">
//         <span style={{ color: "#c21717" }}>ANTI-RAGGING</span>{" "}
//         <span style={{ color: "#c21717" }}>@</span> <span>ADVISORY</span>
//         {
//           userExists ?  <span>
//           <FaPlus color='#c21717' className='ms-2' onClick={openAddModal} style={{ cursor: 'pointer' }} />
//         </span> :""
//         }
//         <p></p>
//       </div>

//       <div className="inception-images" style={{ height: "650px", overflowY: "scroll" }}>
//         {
//           advisory && advisory.map((elem) => (
//             <div key={elem.id} className="pdf-container">
//               <Document
//                 file={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
//                 onLoadError={(error) => console.error('Error loading PDF:', error)}
//                 error="Error loading PDF"
//               >
//                 <Page pageNumber={1} />
//               </Document>
//               {
//                 userExists ? <div className="action-buttons">
//                 <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id, src: `${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`, path: `${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}` })}><FaEdit /></button>
//                 <button className="delete-button" onClick={(e) => {e.stopPropagation(); handleDeleteAdvisory(elem.id)}}><FaTrash /></button>
//               </div> :""
//               }
//             </div>
//           ))
//         }
//       </div>

//       <Modal
//         isOpen={addModalIsOpen}
//         onRequestClose={closeAddModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="Add PDF Modal"
//         className="add-modal-container"
//         overlayClassName="add-modal-overlay"
//       >
//         <div className="add-modal">
//           <div className="add-modal-header">
//             <h2>Add New PDF</h2>
//             <button onClick={closeAddModal} className="close-button">
//               <MdClose />
//             </button>
//           </div>
//           <div className="add-modal-body">
//             <div className="file-input">
//               <label htmlFor="image-upload" className="file-input-label">
//                 <FaUpload className="upload-icon" />
//                 {newImageName ? newImageName : "Upload Image"}
//               </label>
//               <input
//                 id="image-upload"
//                 type="file"
//                 accept="image/*"
//                 onChange={handleNewImageChange}
//               />
//             </div>
//             <div className="file-input">
//               <label htmlFor="pdf-upload" className="file-input-label">
//                 <FaUpload className="upload-icon" />
//                 {newPdfName ? newPdfName : "Upload PDF"}
//               </label>
//               <input
//                 id="pdf-upload"
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleNewPdfChange}
//               />
//             </div>
//           </div>
//           <div className="add-modal-footer">
//             <button onClick={handleAddNew} className="add-button">
//               Add
//             </button>
//           </div>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={editModalIsOpen}
//         onRequestClose={closeEditModal}
//         contentLabel="Edit Advisory"
//         className="modal-content"
//         overlayClassName="modal-overlay"
//       >
//         <div className="modal-header">
//           <h2>Edit Advisory</h2>
//           <button className="close-btn" onClick={closeEditModal}>
//             <FaTimes />
//           </button>
//         </div>
//         <div className="modal-body">
//           {editImagePreview && (
//             <div className="edit-image-preview">
//               <img className='mb-4' style={{ width: "400px" }} src={editImagePreview} alt="Preview" />
//             </div>
//           )}
//           <div className="file-input">
//             <label htmlFor="image-upload">
//               <FaUpload className="upload-icon" />
//               Upload New Image
//               <input
//                 id="image-upload"
//                 type="file"
//                 accept="image/*"
//                 onChange={handleEditImageChange}
//               />
//             </label>
//             {editImageName && <span className="file-name">{editImageName}</span>}
//           </div>
//           <div className="file-input">
//             <label htmlFor="pdf-upload">
//               <FaUpload className="upload-icon" />
//               Upload New PDF
//               <input
//                 id="pdf-upload"
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleEditPdfChange}
//               />
//             </label>
//             {editPdfName && <span className="file-name">{editPdfName}</span>}
//           </div>
//           <div className="pdf-link">
//             <a href={selectedPdf.path} target="_blank" rel="noopener noreferrer">View Old PDF</a>
//           </div>
//           <button className="save-btn" onClick={handleSaveChanges}>
//             Save Changes
//           </button>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Pdf;

// import React, { useState, useEffect } from 'react';
// import Modal from 'react-modal';
// import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
// import { MdClose } from "react-icons/md";
// import { Document, Page } from 'react-pdf';
// import * as pdfjs from 'pdfjs-dist/build/pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry.js';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { Base_Url } from './config';

// Modal.setAppElement('#root');
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// function Pdf() {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [editModalIsOpen, setEditModalIsOpen] = useState(false);
//   const [addModalIsOpen, setAddModalIsOpen] = useState(false);
//   const [selectedPdf, setSelectedPdf] = useState({});
//   const [advisory, setAdvisory] = useState([]);
//   const [newImage, setNewImage] = useState(null);
//   const [editImagePreview, setEditImagePreview] = useState(null);
//   const [newPdf, setNewPdf] = useState(null);
//   const [editImage, setEditImage] = useState(null);
//   const [editPdf, setEditPdf] = useState(null);
//   const [newImageName, setNewImageName] = useState('');
//   const [newPdfName, setNewPdfName] = useState('');
//   const [editImageName, setEditImageName] = useState('');
//   const [editPdfName, setEditPdfName] = useState('');

//   const userExists = useSelector((state) => state.user.userExists);

//   useEffect(() => {
//     fetchAdvisory();
//   }, []);

//   const openEditModal = (e, pdf) => {
//     e.stopPropagation();
//     setSelectedPdf(pdf);
//     setEditImage(null);
//     setEditPdf(null);
//     setEditImagePreview(pdf.src);
//     setEditImageName('');
//     setEditPdfName('');
//     setEditModalIsOpen(true);
//   };

//   const closeEditModal = () => {
//     setEditModalIsOpen(false);
//     setSelectedPdf({});
//   };

//   const handleEditImageChange = (e) => {
//     const file = e.target.files[0];
//     setEditImage(file);
//     setEditImagePreview(URL.createObjectURL(file));
//     setEditImageName(file.name);
//   };

//   const handleEditPdfChange = (e) => {
//     const file = e.target.files[0];
//     setEditPdf(file);
//     setEditPdfName(file.name);
//   };

//   const handleSaveChanges = async () => {
//     const formData = new FormData();
//     if (editImage) formData.append('image', editImage);
//     if (editPdf) formData.append('pdf', editPdf);

//     try {
//       const response = await axios.put(`${Base_Url}/update-advisory/${selectedPdf.id}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.status === 200) {
//         closeEditModal();
//         fetchAdvisory();
//       } else {
//         console.error('Error updating advisory:', response.data);
//       }
//     } catch (error) {
//       console.error('Error updating advisory:', error);
//     }
//   };

//   const openAddModal = () => {
//     setAddModalIsOpen(true);
//   };

//   const closeAddModal = () => {
//     setAddModalIsOpen(false);
//     setNewImage(null);
//     setNewPdf(null);
//     setNewImageName('');
//     setNewPdfName('');
//   };

//   const handleNewImageChange = (e) => {
//     const file = e.target.files[0];
//     setNewImage(file);
//     setNewImageName(file.name);
//   };

//   const handleNewPdfChange = (e) => {
//     const file = e.target.files[0];
//     setNewPdf(file);
//     setNewPdfName(file.name);
//   };

//   const handleAddNew = async () => {
//     const formData = new FormData();
//     if (newImage) formData.append('image', newImage);
//     if (newPdf) formData.append('pdf', newPdf);

//     try {
//       const response = await axios.post(`${Base_Url}/create-advisory`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.status === 200 || response.status === 201) {
//         closeAddModal();
//         setNewImage(null);
//         setNewPdf(null);
//         fetchAdvisory();
//       } else {
//         console.error('Failed to upload PDF and image:', response.data);
//       }
//     } catch (error) {
//       console.error('Error uploading PDF and image:', error);
//     }
//   };

//   const fetchAdvisory = async () => {
//     try {
//       const response = await axios.get(`${Base_Url}/advisories`);
//       setAdvisory(response.data);
//     } catch (error) {
//       console.error('Error fetching advisories:', error);
//     }
//   };

//   const handleDeleteAdvisory = async (id) => {
//     const confirmed = window.confirm('Are you sure you want to delete this advisory?');
//     if (confirmed) {
//       try {
//         const response = await axios.delete(`${Base_Url}/advisory/${id}`);
//         if (response.status === 200) {
//           fetchAdvisory();
//         } else {
//           console.error('Error deleting advisory:', response.data);
//         }
//       } catch (error) {
//         console.error('Error deleting advisory:', error);
//       }
//     }
//   };

//   return (
//     <div className="pdf">
//       <div className="h1 mb-4 text-center text-dark">
//         <span style={{ color: '#c21717' }}>ANTI-RAGGING</span>{' '}
//         <span style={{ color: '#c21717' }}>@</span> <span>ADVISORY</span>
//         {userExists ? (
//           <span>
//             <FaPlus color='#c21717' className='ms-2' onClick={openAddModal} style={{ cursor: 'pointer' }} />
//           </span>
//         ) : (
//           ''
//         )}
//       </div>

//       <div className="inception-images" style={{ height: '650px', overflowY: 'scroll' }}>
//         {advisory &&
//           advisory.map((elem) => (
//             <div key={elem.id} className="pdf-container">
//              <Document
//   file={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
//   onLoadError={(error) => console.error('Error loading PDF:', error)}
//   error="Error loading PDF"
// >
//   <Page pageNumber={1} />
// </Document>

//               {userExists ? (
//                 <div className="action-buttons">
//                   <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id, src: `${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`, path: `${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}` })}>
//                     <FaEdit />
//                   </button>
//                   <button className="delete-button" onClick={(e) => { e.stopPropagation(); handleDeleteAdvisory(elem.id); }}>
//                     <FaTrash />
//                   </button>
//                 </div>
//               ) : (
//                 ''
//               )}
//             </div>
//           ))}
//       </div>

//       <Modal
//         isOpen={addModalIsOpen}
//         onRequestClose={closeAddModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="Add PDF Modal"
//         className="add-modal-container"
//         overlayClassName="add-modal-overlay"
//       >
//         <div className="add-modal">
//           <div className="add-modal-header">
//             <h2>Add New PDF</h2>
//             <button onClick={closeAddModal} className="close-button">
//               <MdClose />
//             </button>
//           </div>
//           <div className="add-modal-body">
//             <div className="form-group">
//               <label htmlFor="newImage">Image (Optional):</label>
//               <input type="file" id="newImage" name="newImage" onChange={handleNewImageChange} accept=".jpg,.jpeg,.png" />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPdf">PDF:</label>
//               <input type="file" id="newPdf" name="newPdf" onChange={handleNewPdfChange} accept=".pdf" required />
//             </div>
//           </div>
//           <div className="add-modal-footer">
//             <button className="add-button" type="button" onClick={handleAddNew} disabled={!newPdf}>
//               Add PDF
//             </button>
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Pdf;

// import React, { useState, useEffect } from 'react';
// import Modal from 'react-modal';
// import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
// import { MdClose } from "react-icons/md";
// import { Document, Page, pdfjs } from 'react-pdf';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { Base_Url } from './config';

// Modal.setAppElement('#root');
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// function Pdf() {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [editModalIsOpen, setEditModalIsOpen] = useState(false);
//   const [addModalIsOpen, setAddModalIsOpen] = useState(false);
//   const [selectedPdf, setSelectedPdf] = useState({});
//   const [advisory, setAdvisory] = useState([]);
//   const [newImage, setNewImage] = useState(null);
//   const [editImagePreview, setEditImagePreview] = useState(null);
//   const [newPdf, setNewPdf] = useState(null);
//   const [editImage, setEditImage] = useState(null);
//   const [editPdf, setEditPdf] = useState(null);
//   const [newImageName, setNewImageName] = useState('');
//   const [newPdfName, setNewPdfName] = useState('');
//   const [editImageName, setEditImageName] = useState('');
//   const [editPdfName, setEditPdfName] = useState('');

//   const userExists = useSelector((state) => state.user.userExists);

//   useEffect(() => {
//     fetchAdvisory();
//   }, []);

//   const openEditModal = (e, pdf) => {
//     e.stopPropagation();
//     setSelectedPdf(pdf);
//     setEditImage(null);
//     setEditPdf(null);
//     setEditImagePreview(pdf.src);
//     setEditImageName('');
//     setEditPdfName('');
//     setEditModalIsOpen(true);
//   };

//   const closeEditModal = () => {
//     setEditModalIsOpen(false);
//     setSelectedPdf({});
//   };

//   const handleEditImageChange = (e) => {
//     const file = e.target.files[0];
//     setEditImage(file);
//     setEditImagePreview(URL.createObjectURL(file));
//     setEditImageName(file.name);
//   };

//   const handleEditPdfChange = (e) => {
//     const file = e.target.files[0];
//     setEditPdf(file);
//     setEditPdfName(file.name);
//   };

//   const handleSaveChanges = async () => {
//     const formData = new FormData();
//     if (editImage) formData.append('image', editImage);
//     if (editPdf) formData.append('pdf', editPdf);

//     try {
//       const response = await axios.put(`${Base_Url}/update-advisory/${selectedPdf.id}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.status === 200) {
//         closeEditModal();
//         fetchAdvisory();
//       } else {
//         console.error('Error updating advisory:', response.data);
//       }
//     } catch (error) {
//       console.error('Error updating advisory:', error);
//     }
//   };

//   const openAddModal = () => {
//     setAddModalIsOpen(true);
//   };

//   const closeAddModal = () => {
//     setAddModalIsOpen(false);
//     setNewImage(null);
//     setNewPdf(null);
//     setNewImageName('');
//     setNewPdfName('');
//   };

//   const handleNewImageChange = (e) => {
//     const file = e.target.files[0];
//     setNewImage(file);
//     setNewImageName(file.name);
//   };

//   const handleNewPdfChange = (e) => {
//     const file = e.target.files[0];
//     setNewPdf(file);
//     setNewPdfName(file.name);
//   };

//   const handleAddNew = async () => {
//     const formData = new FormData();
//     if (newImage) formData.append('image', newImage);
//     if (newPdf) formData.append('pdf', newPdf);

//     try {
//       const response = await axios.post(`${Base_Url}/create-advisory`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.status === 200 || response.status === 201) {
//         closeAddModal();
//         setNewImage(null);
//         setNewPdf(null);
//         fetchAdvisory();
//       } else {
//         console.error('Failed to upload PDF and image:', response.data);
//       }
//     } catch (error) {
//       console.error('Error uploading PDF and image:', error);
//     }
//   };

//   const fetchAdvisory = async () => {
//     try {
//       const response = await axios.get(`${Base_Url}/advisories`);
//       setAdvisory(response.data);
//     } catch (error) {
//       console.error('Error fetching advisories:', error);
//     }
//   };

//   const handleDeleteAdvisory = async (id) => {
//     const confirmed = window.confirm('Are you sure you want to delete this advisory?');
//     if (confirmed) {
//       try {
//         const response = await axios.delete(`${Base_Url}/advisory/${id}`);
//         if (response.status === 200) {
//           fetchAdvisory();
//         } else {
//           console.error('Error deleting advisory:', response.data);
//         }
//       } catch (error) {
//         console.error('Error deleting advisory:', error);
//       }
//     }
//   };

//   return (
//     <div className="pdf">
//       <div className="h1 mb-4 text-center text-dark">
//         <span style={{ color: '#c21717' }}>ANTI-RAGGING</span>{' '}
//         <span style={{ color: '#c21717' }}>@</span> <span>ADVISORY</span>
//         {userExists ? (
//           <span>
//             <FaPlus color='#c21717' className='ms-2' onClick={openAddModal} style={{ cursor: 'pointer' }} />
//           </span>
//         ) : (
//           ''
//         )}
//       </div>

//       <div className="inception-images" style={{ height: '650px', overflowY: 'scroll' }}>
//         {advisory &&
//           advisory.map((elem) => (
//             <div key={elem.id} className="pdf-container">
//               <Document
//                 file={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
//                 onLoadError={(error) => console.error('Error loading PDF:', error)}
//                 error="Error loading PDF"
//               >
//                 <Page pageNumber={1} />
//               </Document>
//               {userExists ? (
//                 <div className="action-buttons">
//                   <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id, src: `${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`, path: `${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}` })}>
//                     <FaEdit />
//                   </button>
//                   <button className="delete-button" onClick={(e) => { e.stopPropagation(); handleDeleteAdvisory(elem.id); }}>
//                     <FaTrash />
//                   </button>
//                 </div>
//               ) : (
//                 ''
//               )}
//             </div>
//           ))}
//       </div>

//       <Modal
//         isOpen={addModalIsOpen}
//         onRequestClose={closeAddModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="Add PDF Modal"
//         className="add-modal-container"
//         overlayClassName="add-modal-overlay"
//       >
//         <div className="add-modal">
//           <div className="add-modal-header">
//             <h2>Add New PDF</h2>
//             <button onClick={closeAddModal} className="close-button">
//               <MdClose />
//             </button>
//           </div>
//           <div className="add-modal-body">
//             <div className="form-group">
//               <label htmlFor="newImage">Image (Optional):</label>
//               <input type="file" id="newImage" name="newImage" onChange={handleNewImageChange} accept=".jpg,.jpeg,.png" />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPdf">PDF:</label>
//               <input type="file" id="newPdf" name="newPdf" onChange={handleNewPdfChange} accept=".pdf" required />
//                </div>
//              </div>
//              <div className="add-modal-footer">
//                <button className="add-button" type="button" onClick={handleAddNew} disabled={!newPdf}>
//                  Add PDF
//                </button>
//              </div>
//            </div>
//          </Modal>
//        </div>
//      );
//    }

//    export default Pdf;

