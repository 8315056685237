import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Button } from 'react-bootstrap';
import ResetPassword from './ResetPassword';
import { toast, ToastContainer } from 'react-toastify';
import { Base_Url} from "./config";
function ForgotPassword({ show, handleClose }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [enteredOtp, setEnteredOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [disableRequestOTP, setDisableRequestOTP] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setDisableRequestOTP(true); // Disable the button
      const response = await axios.post(`${Base_Url}/admin-checkemail`, { email });

      if (response.data.exists) {
        const otpResponse = await axios.post(`${Base_Url}/admin-sendotp`, { email });
        setOtp(otpResponse.data.otp);
        toast.success('Otp Sent To Your Email and valid for 40 sec');
        setShowOtpField(true);

        // Enable the button after 40 seconds
        setTimeout(() => {
          setDisableRequestOTP(false);
        }, 40000);
      } else {
        setMessage('Email does not exist in the database.');
        toast.error('Email does not exist in the database.');
        setDisableRequestOTP(false); // Enable the button in case of an error
      }
    } catch (error) {
      setMessage('Error requesting password reset. Please try again.');
      toast.error('Error requesting password reset. Please try again.');
      setDisableRequestOTP(false); // Enable the button in case of an error
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (otp === enteredOtp) {
      setShowOtpField(false); // Close the OTP input field
      setShowResetModal(true); // Open the ResetPassword modal
      handleClose(); // Close the Forgot Password modal
    } else {
      toast.error('Invalid OTP. Please try again.');
    }
  };

  const handleResendOtp = async () => {
    try {
      const otpResponse = await axios.post(`${Base_Url}/admin-sendotp`, { email });
      setOtp(otpResponse.data.otp);
      toast.success('Otp Resent To Your Email');
    } catch (error) {
      toast.error('Error resending OTP. Please try again.');
    }
  };

  const handleCloseResetModal = () => {
    setShowResetModal(false);
  };

  return (
    <>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"

                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-4" disabled={disableRequestOTP}>
              Request OTP
            </Button>
          </Form>
          {message && <p>{message}</p>}

          {showOtpField && (
            <Form onSubmit={handleOtpSubmit}>
              <Form.Group controlId="otp">
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={enteredOtp}
                  onChange={(e) => setEnteredOtp(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit OTP
              </Button>
            </Form>
          )}

          {/* Resend OTP button */}
          <Button variant="link" onClick={handleResendOtp}>
            Resend OTP
          </Button>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>

      {showResetModal && (
        <ResetPassword email={email} otp={otp} show={showResetModal} handleClose={handleCloseResetModal} />
      )}

      <ToastContainer />
    </>
  );
}

export default ForgotPassword;
