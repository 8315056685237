import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { MdKeyboardDoubleArrowRight,MdKeyboardDoubleArrowLeft } from "react-icons/md";
import "./Main.scss";
import iitdelhi from "../Assests/iitdelhi.jpg";
import hostels from "../Assests/hostels.jpg";
import teams from "../Assests/teams.jpg";
import nights from "../Assests/bhm1.jpg";
import boards from "../Assests/collect.jpg";
import ncc from "../Assests/ncc.jpg";
import sports from "../Assests/sports.jpg";
import nss from "../Assests/nss.jpg";
import { Base_Url} from "./config";
import { Link } from "react-router-dom";
import { ImArrowUpRight } from "react-icons/im";

const CitiesSlider = ({ slides }) => {
  const IMAGE_PARTS = 4;
  const AUTOCHANGE_TIME = 4000;

  const [activeSlide, setActiveSlide] = useState(0);
  const [sliderReady, setSliderReady] = useState(false);

  useEffect(() => {
    const changeTO = setTimeout(() => {
      changeSlides(1);
    }, AUTOCHANGE_TIME);

    return () => {
      clearTimeout(changeTO);
    };
  }, [activeSlide]);

  const changeSlides = (change) => {
    const length = slides.length;
    setActiveSlide((prev) => (prev + change + length) % length);
  };

  useEffect(() => {
    const initSlider = setTimeout(() => {
      setSliderReady(true);
    }, 0);

    return () => {
      clearTimeout(initSlider);
    };
  }, []);

  return (
    <div className={classNames("slider mt-3 sliderBorderradius", { "s--ready": sliderReady })}>
      <div className="slider__slides">
        {slides.map((slide, index) => (
          <div
            className={classNames("slider__slide", {
              "s--active": activeSlide === index,
            })}
            key={slide.city}
          >
            <div className="slider__slide-content">
              <h3 className="slider__slide-subheading">
                {slide.country || slide.city}
              </h3>
              <h2 className="slider__slide-heading">
                {slide.city.split("").map((l, i) => (
                  <span key={i}>{l}</span>
                ))}
              </h2>
            </div>
            <div className="slider__slide-parts">
              {[...Array(IMAGE_PARTS).fill()].map((_, i) => (
                <div className="slider__slide-part" key={i}>
                  <div
                    className="slider__slide-part-inner"
                    style={{ backgroundImage: `url(${slide.img})` }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="slider__control" onClick={() => changeSlides(-1)}>
        {/* <MdKeyboardDoubleArrowLeft /> */}
      </div>
      <div
        className="slider__control slider__control--right"
        onClick={() => changeSlides(1)}
      >
        {/* <MdKeyboardDoubleArrowRight /> */}
      </div>
    </div>
  );
};



const App = () => {
  const slides = [
    {
      city: "IITD",
      country: " ",
      img: iitdelhi,
    },
    {
      city: "HOSTELS",
      country: "IITD",
      img: hostels,
    },
    {
      city: "TEAMS",
      country: "STUDENT",
      img: teams,
    },
    {
      city: "NIGHTS",
      country: "BHM",
      img:nights,
    },
    {
      city: "NIGHT",
      country: "ALL BOARDS",
      img: boards,
    },
    {
      city: "NCC",
      country: "IITD",
      img: ncc,
    },
    {
      city: "SPORTS",
      country: "IITD",
      img:sports,
    },
    {
      city: "NSS",
      country: "IITD",
      img: nss,
    },

  ];

  const [marginTop, setMarginTop] = useState("90px");

    useEffect(() => {
        const handleResize = () => {
            const newMarginTop = window.innerWidth <= 576 ? "0" : "90px";
            setMarginTop(newMarginTop);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [marginTop2, setMarginTop2] = useState("100px");

    useEffect(() => {
        const handleResize = () => {
            const newMarginTop = window.innerWidth <= 576 ? "0" : "100px";
            setMarginTop2(newMarginTop);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 " style={{marginTop:marginTop}}>
          <div id="app" className="  mt-4 w-100">
            <CitiesSlider slides={slides} />
          </div>
        </div>
        <div className="col-lg-4" style={{marginTop:marginTop2}}>
          <div className="col-lg-4 ">
            <div className="innerRight ">
              <table className="content-table">
                <thead>
                  <tr>
                    <th></th>
                    <th className="importantLink">Important Links</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <h4 className="kk-bold text-theme-colored2 d-flex align-items-center">
                        <MdKeyboardDoubleArrowRight className="li2icon" />
                        <a
                          className="li2"
                          style={{ fontSize: "17px", color: "#333333" }}
                          href="https://bsw.iitd.ac.in/counselling.php"
                          target="_blank"
                        >
                          <span className="li2span">COUNSELLING  <ImArrowUpRight className="icons ml-2" color="#c21717" style={{fontSize:"12px"}}/></span>
                        </a>
                      </h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <h4 className="kk-bold text-theme-colored2 d-flex align-items-center">
                        <MdKeyboardDoubleArrowRight className="li2icon" />
                        <a
                          className="li2"
                          style={{ fontSize: "17px", color: "#333333" }}
                          href="https://hospital.iitd.ac.in/"
                          target="_blank"
                        >
                          <span className="li2span">IITD HOSPITAL  <ImArrowUpRight className="icons ml-2" color="#c21717" style={{fontSize:"12px"}} /></span>
                        </a>
                      </h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr className="active-row">
                    <td></td>
                    <td>
                      <h4 className="kk-bold text-theme-colored2 d-flex align-items-center">
                        <MdKeyboardDoubleArrowRight className="li2icon" />
                        <a
                          className="li2"
                          style={{ fontSize: "17px", color: "#333333" }}
                          href="https://library.iitd.ac.in/"
                          target="_blank"
                        >
                          <span className="li2span">CENTRAL LIBRARY  <ImArrowUpRight className="icons ml-2" color="#c21717" style={{fontSize:"12px"}}/></span>
                        </a>
                      </h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr className="active-row">
                    <td></td>
                    <td>
                      <h4 className="kk-bold text-theme-colored2 d-flex align-items-center">
                        <MdKeyboardDoubleArrowRight className="li2icon" />
                        <Link
                          className="li2"
                          style={{ fontSize: "17px", color: "#333333" }}
                          to="/antiRagging"
                        >
                          <span className="li2span">ANTI RAGGING</span>
                        </Link>
                      </h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr className="active-row">
                    <td></td>
                    <td>
                      <h4 className="kk-bold text-theme-colored2 d-flex align-items-center">
                        <MdKeyboardDoubleArrowRight className="li2icon" />
                        <a
                          className="li2"
                          style={{ fontSize: "17px", color: "#333333" }}
                          href="https://bsw.iitd.ac.in/links.php"
                          target="_blank"
                        >
                          <span className="li2span">OTHER LINKS  <ImArrowUpRight className="icons ml-2" color="#c21717" style={{fontSize:"12px"}} /></span>
                        </a>
                      </h4>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
