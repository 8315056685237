import React, { useEffect, useRef, useState } from "react";
import "./AntiRag.css"; // Import the new CSS file
import { AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import axios from "axios";
import { FaEdit, FaTrash, FaSave, FaPlus, FaTrashAlt } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { selectUserExists } from "../Redux/userSlice";
import { useLocation } from "react-router-dom";
import { Base_Url} from "./config";


const Sidebar = ({ isOpen, toggleSidebar }) => {

  const [antiPolicy, setAntiPolicy] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [data, setData] = useState([]);
  
  
  const [antiPolicyName,setAntiPolicyName]= useState("")
  const [antiPolicyPdf,setAntiPolicyPdf]= useState("")
  const [currentAntiPolicyId,setCurrentAntiPolicyId]= useState("")

  const userExists = useSelector((state) => state.user.userExists);
  const [isEdit,setEdit]= useState(userExists)
  
  
  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log(userDataRedux,"userData")

  const handleFileChange = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const addPolicy = () => {
    const formData = new FormData();
    formData.append('pdf', pdfFile);
    formData.append('pdf_name', antiPolicy);
    formData.append('created_by',   userDataRedux?.user_id);
  
    axios.post(`${Base_Url}/uploadpdf`, formData)
      .then((response) => {
        toast.success('Policy Created Successful');
        fetchData()
        closeButtonRef.current.click();
        // Handle success, e.g., show a success message
      })
      .catch((error) => {
        // console.error('Error uploading PDF:', error);
        // Handle error, e.g., show an error message
      });
  };
  

 

  useEffect(() => {
    // Fetch data from your API when the component mounts
    fetchData();
  }, []);

  const fetchData = () => {
    // Fetch data from your API endpoint
    fetch(`${Base_Url}/pdfs`)
      .then(response => response.json())
      .then(data => {
        // Once data is fetched, update the state
        setData(data);
      })
      // .catch(error => console.error('Error fetching data:', error));
  };
  // anti-policy-soft-delete

  const markAsDeleted = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Policy?");
    
    if (confirmDelete) {
      // Ensure userDataRedux is not null or undefined and has at least one element
      if (userDataRedux && !userDataRedux == "" ) {
        axios.put(`${Base_Url}/anti-policy-soft-delete/${id}`, {
          deleted_by:  userDataRedux?.user_id
        })
        .then(response => {
          toast.success("Deleted successfully");
          fetchData();
          // Optionally, you can update the UI or perform any other actions after marking the member as deleted
        })
        .catch(error => {
          // console.error('Error marking member as deleted:', error);
          // Handle error, e.g., show an error message to the user
        });
      } else {
        // console.error('userDataRedux is null, undefined, or empty');
        // Handle the case where userDataRedux is not properly initialized or empty
      }
    }
  };
  

  const handleEdit= async(obj)=>{
    // console.log(obj)
    setCurrentAntiPolicyId(obj?.id)
   
      try {
        const response = await axios.get(`${Base_Url}/pdfs/${obj.id}`);
        setAntiPolicyPdf(response.data.pdf_path)
        setAntiPolicyName(response.data.pdf_name)
      } catch (error) {
        // console.error('Error fetching PDF data:', error);
      }
  }


  
  const handleFileChangeEdit = (e) => {
    const file = e.target.files[0];
    // setFileName(file)
    // setSelectedBoard(prevSelectedBoard => ({
    //   ...prevSelectedBoard,
    //   board_constitution_pdf: file
    // }));

    setAntiPolicyPdf(file)
    // console.log(file)
  };
  
  // console.log(antiPolicyName)

  const updatePolicy = async () => {
    try {
      const formData = new FormData();
      formData.append('pdf', antiPolicyPdf); // Make sure antiPolicyPdf is not null
      formData.append('pdf_name', antiPolicyName); // Make sure antiPolicyName is not null
      formData.append('updated_by',   userDataRedux?.user_id);// Make sure antiPolicyName is not null
  
      const response = await axios.put(
        `${Base_Url}/updatePolicy/${currentAntiPolicyId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      // console.log(response.data); // Handle response data as needed
      toast.success('Updated successfully');
      setCurrentAntiPolicyId("");
      setAntiPolicyPdf("");
      setAntiPolicyName("");
      closeButtonRef2.current.click();
      fetchData();
    } catch (error) {
      // console.error('Error updating PDF file:', error);
      toast.error('Error updating PDF file. Please try again later.');
    }
  };


  const closeButtonRef = useRef(null);
  const closeButtonRef2 = useRef(null);


  const [chairpersons, setChairpersons] = useState([]);

  useEffect(() => {
    fetchDataChairperson();
  }, []);

  const fetchDataChairperson = async () => {
    try {
      const response = await axios.get(`${Base_Url}/antiragging_chairperson`); // Assuming your server is running on the same host/port
      setChairpersons(response.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  
  // console.log(chairpersons)
  return (

    <>
    {/* 1st modal  */}
     <div className="modal fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
            <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Add Anti-Ragging Policy</h5>
            <button className="text-white close" type="button" ref={closeButtonRef} data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "20px" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="name">Anti Ragging Policy :</label>
                          <input
                            type="text"
                            className="p-2 form-control mt-2"
                            id="name"
                            name="antiPolicy"
                            placeholder="Anti Ragging Policy"
                            value={antiPolicy}
                            onChange={(e) => setAntiPolicy(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="image">Upload Policy:</label>
                          <input
                            type="file"
                            name="pdf"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            className="form-control-file mt-2"
                            id="constitutionInput" // Unique ID
                          />
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={() => document.getElementById('constitutionInput').click()}
                          >
                            {pdfFile?.name ? pdfFile?.name : "Upload"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-success mt-4 btn-block w-25"
                      onClick={addPolicy}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* 2nd modal  */}
    <div className="modal fade" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
            <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Edit Anti-Ragging Policy</h5>
            <button className="text-white close" type="button" ref={closeButtonRef2}  data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "20px" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="name">Anti Ragging Policy :</label>
                          <input
                            type="text"
                            className="p-2 form-control mt-2"
                            id="name"
                            name="board_name"
                            placeholder="Enter name"
                            value={antiPolicyName}
                            onChange={(e)=>setAntiPolicyName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="image">Anti Ragging Policy Pdf:</label>
                          <input
                            type="file"
                            name="board_constitution_pdf"
                            onChange={handleFileChangeEdit}
                            style={{ display: "none" }}
                            className="form-control-file mt-2"
                            id="constitutionInput2" // Unique ID
                          />
                          <button type="button" className="btn btn-secondary mt-2" onClick={() => document.getElementById('constitutionInput2').click()}>
                            {antiPolicyPdf?.name ? antiPolicyPdf?.name :antiPolicyPdf}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button type="button" className="btn btn-success mt-4 btn-block w-25"
                     onClick={updatePolicy}
                     >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    {/* modal end */}
    </>
  );
};

const ManageDosUsers = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  

    
  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log( userDataRedux?.user_id,"userData")

  const [data, setData] = useState([]);
  const [editBool, setEditBool] = useState(false);

  const userExists = useSelector(selectUserExists);
  // console.log(userExists, 'userexists');

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Base_Url}/dos-users`);
      // console.log(response); // Replace with your API endpoint
      setData(response.data); // Update the state with the fetched data

    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);



  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this member?");
    if (confirmDelete) {
      try {
        const deletedBy =  userDataRedux?.user_id; // Assuming you have access to the user's ID
        const response = await axios.delete(`${Base_Url}/dos-user-delete/${id}`, {
        });
  
        // console.log(response.data);
        toast.success('Contact Deleted successfully!');
        fetchData();
        // Handle success, e.g., show a success message to the user
      } catch (error) {
        // console.error(error);
        toast.error('Error deleting category. Please try again.');
        // Handle error, e.g., show an error message to the user
      }
    }
  };
  


  const [editedData, setEditedData] = useState({
    id: null,
    name: "",
    user_id: "",
    category: "",
  });

  const handleEdit = (id) => {
    const editedItem = data.find((item) => item.id === id);
    setEditedData({
      id: editedItem.id,
      name: editedItem.name,
      user_id: editedItem.user_id,
      category: editedItem.category,
      department: editedItem.department,
    });
    setEditBool(true);
  };


  // console.log(editedData)
  const handleSave = async () => {
    try {
      if (editedData.id) {
        // Existing data, update
        const updatedData = { ...editedData, updated_by:  userDataRedux?.user_id };
        await axios.put(`${Base_Url}/dos-user-update/${editedData.id}`, updatedData);
        // console.log('Data updated successfully');
        toast.success('Contact updated successfully!');
      } else {
        // New data, add
        const newData = { ...editedData, created_by:  userDataRedux?.user_id };
        await axios.post(`${Base_Url}/dos-user-add`, newData);
        // console.log('Data added successfully');
        toast.success('Contact added successfully!');
      }
  
      setEditBool(false);
      fetchData(); // Refresh the data after updating or adding
    } catch (error) {
      // console.error('Error saving data:', error);
      toast.error('Error saving category. Please try again.');
    }
  };
  

  

  const handleAdd = () => {
    const newContact = {
      name: "",
      user_id: "",
      category: "",
      department: "",
    };

    // setData([...data, newContact]);
    setData([...data, { ...newContact, created_by:  userDataRedux?.user_id}]);
    setEditBool(true);
    if (editedData.id) {
      setEditedData({ ...newContact, updated_by: userDataRedux?.user_id });
    }
    else{
      setEditedData({ ...newContact });
    }

    // Set isNewContact to true when adding a new category
    setIsNewContact(true);
    toast.info('Adding a new category.');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancel = () => {
    setEditBool(false);
    fetchData();
    toast.info('Editing canceled.');
  };


  const handleDeleteLastAdded = () => {
    const lastAddedId = data[data.length - 1]?.id;

    if (lastAddedId) {
      handleDelete(lastAddedId);
      toast.info('Last added category deleted.');
    } else {
      setData(data.slice(0, data.length - 1));
      toast.info('Last added category removed.');
    }
  };
  // console.log(data)


  const accessAction1 = localStorage.getItem("login")
  const accessAction2 = localStorage.getItem("userId")


  // console.log(accessAction1)
  // console.log(accessAction2)


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Attach event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();
  const [isHomeRoute, setIsHomeRoute] = useState(false);
  useEffect(() => {
    // Check if the current route is the home route
    setIsHomeRoute(location.pathname === '/antiRagging');
  }, [location.pathname]);
  return (
    <>

      <style>
        {`
        /* Only apply the styles when on the home route */
        ${isHomeRoute && `
          ::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }
          
          ::-webkit-scrollbar-thumb {
            background-color: #888;
          }
          
          ::-webkit-scrollbar-corner {
            background-color: #f1f1f1;
          }

          ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
        `}
      `}
      </style>

      <div>
        <div class="box-wrap">
          <div class="table-wrap-manage-users">
            <div class="h1  mb-4  mt-5 text-center text-dark">
              <span style={{ color: "#c21717" }}> MANAGE DOS</span>{" "}
              <span style={{ color: "#c21717" }}>@ </span>USERS
              <p>
                {" "}

                {/* <h6> IIT DELHI:-</h6> */}
              </p>
            </div>
            <table>
              <thead>
                <tr>
                   <th className="thanti">sr no.</th> 
                  {/* <th className="thanti">Name</th> */}
                  {/* <th className="thanti"> Sr No. </th> */}
                  <th className="thanti">User Id</th>
                  {/* <th className="thanti">Category</th>
                  <th className="thanti">Department</th> */}
                  {
                    userExists ? <th className="thanti">Actions</th> : ""
                  }

                </tr>
              </thead>
              <tbody>
                { data && data
                  ?.filter((elem) => !elem.is_deleted) 
                  .map((elem, index) => (
                    <tr key={index}>
                       <td className="tdanti">{index + 1}</td>

                      {/* <td className="tdanti">
                        {editBool && editedData.id === elem.id ? (
                          <input
                            style={{ border: "none" }}
                            type="text"
                            name="name"
                            value={editedData.name}
                            onChange={handleInputChange}
                            className="inputchnage"
                            autoFocus
                          />
                        ) : (
                          elem.name
                        )}
                      </td> */}
                      <td className="tdantimanageusers" style={{textTransform:"none"}}>
                        {editBool && editedData.id === elem.id ? (
                          <input
                            style={{ border: "none", textTransform:"none" }}
                            type="text"
                            name="user_id"
                            value={editedData.user_id}
                            onChange={handleInputChange}
                            className="inputchnage"
                          />
                        ) : (
                          elem.user_id
                        )}
                      </td>
                      {/* <td className="tdanti">
                        {editBool && editedData.id === elem.id ? (
                          <input
                            style={{ border: "none" }}
                            type="text"
                            name="category"
                            value={editedData.category}
                            onChange={handleInputChange}
                            className="inputchnage"
                          />
                        ) : (
                          elem.name
                        )}
                      </td> */}
                      {/* <td className="tdanti">
                        {editBool && editedData.id === elem.id ? (
                          <input
                            style={{ border: "none" }}
                            type="text"
                            name="department"
                            value={editedData.department}
                            onChange={handleInputChange}
                            className="inputchnage"
                          />
                        ) : (
                          elem.department
                        )}
                      </td> */}
                      {
                        userExists ? <td className="tdanti action-icons" >
                          {!editBool && (
                            <FaEdit
                              className="action-icon me-2"
                              onClick={() => handleEdit(elem.id)}
                            />
                          )}
                          {editBool && editedData.id === elem.id && (
                            <>
                              <FaSave
                                className="action-icon me-2 mt-1"
                                onClick={handleSave} 
                              />
                              <AiOutlineClose
                                className="action-icon mt-1"
                                onClick={handleCancel}
                              />
                            </>
                          )}
                          {!editBool && (
                            <FaTrash
                              className="action-icon"
                              onClick={() => handleDelete(elem.id)}
                            />
                          )}
                        </td> : ""
                      }

                    </tr>
                  ))}
              </tbody>
            </table>
       
            {
              userExists && <div className="d-flex justify-content-end align-items-center">
                <button className="add-contact-btn d-flex" onClick={handleAdd}>
                  <span className="me-2 " style={{ marginRight: "10px" }}>
                    {windowWidth >= 767 ? 'Add User' : 'Add User'}</span>{" "}
                  <FaCirclePlus className="mb-1" style={{ fontSize: "18px" }} />
                </button>
              </div>
            }

          </div>
        </div>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <ToastContainer />
      </div>
    </>
  );
};

export default ManageDosUsers;
