import React, { useEffect, useRef, useState } from "react";
import "./About.min.css";
import { FaEye, FaUsers, FaEdit, FaUpload, FaCheck, FaTrash } from "react-icons/fa"; // Import FaUpload icon
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Base_Url} from "./config";

const AboutUs = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(null);
  const [tempName, setTempName] = useState("");
  const [tempRole, setTempRole] = useState("");
  const [tempMoreInfoLink, setTempMoreInfoLink] = useState("");
  const [tempImage, setTempImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const userExists = useSelector((state) => state.user.userExists);
  const [edit, setEdit] = useState(userExists);


  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log(userDataRedux,"userData")

  useEffect(() => {
    fetchData();
  }, []);

  //  console.log(Base_Url)
  //  console.log(edit)
  


  const fetchData = async () => {
    try {
      const response = await axios.get(`${Base_Url}/admin-aboutus`);
      setTeamMembers(response.data.map(member => {
        // If image_data is available, convert it to a data URL
        if (member.image_data) {
          return {
            ...member,
            image_data: `${Base_Url}/${member.image_data}` // Adjust image URL here
          };
        }
        return member;
      }));
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  const fileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleEditClick = (index) => {
    setSelectedMemberIndex(index);
    const member = teamMembers[index];
    setTempName(member.name);
    setTempRole(member.role);
    setTempMoreInfoLink(member.more_info_link);
    setShowMoreInfo(true);
  };

  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    setTempImage(image);
    setPreviewImage(URL?.createObjectURL(image)); // Set preview image URL
  };

// console.log(userDataRedux)
  const saveChanges = async (index) => {
    try {
      const formData = new FormData();
      formData.append('name', tempName);
      formData.append('role', tempRole);
      formData.append('more_info_link', tempMoreInfoLink);
      formData.append('updated_by',  userDataRedux?.user_id);

      // Append image_data only if tempImage is null (no new image uploaded)
      if (!tempImage) {
        const imageURL = teamMembers[index].image_data.replace(/^.*?uploads[\\\/]/, 'uploads\\');
        formData.append('image_data', imageURL);
      } else {
        formData.append('image', tempImage);
      }

      const response = await axios.put(
        `${Base_Url}/admin-update-aboutus/${teamMembers[index].id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.status === 200) {
        // Update the local state with the updated member details
        const updatedMembers = [...teamMembers];
        updatedMembers[index] = {
          ...updatedMembers[index],
          name: tempName,
          role: tempRole,
          more_info_link: tempMoreInfoLink,
          // Use the existing image_data URL if tempImage is null
          image_data: tempImage ? `${Base_Url}/${response.data.image_data}` : updatedMembers[index].image_data
        };
        setTeamMembers(updatedMembers);
        setSelectedMemberIndex(null);
        setTempImage(null);
        setShowMoreInfo(false);
        fetchData()
        toast.success('Data Updated Succesfully')
      } else {
        // console.error('Error updating member:', response.data);
      }
    } catch (error) {
      // console.error('Error updating data:', error);
    }
  };



  const [formData2, setFormData2] = useState({
    name: '',
    role: '',
    moreInfoLink: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData2((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // console.log(formData2)
  
  const addMember = async () => {
    try {
      // Check if all required fields are filled
      if (formData2.name && formData2.role && formData2.moreInfoLink && tempImage) {
        const formData = new FormData();
        formData.append('name', formData2.name);
        formData.append('role', formData2.role);
        formData.append('more_info_link', formData2.moreInfoLink);
        formData.append('created_by', userDataRedux?.user_id);
        formData.append('image', tempImage); // Append the image file to FormData

        const response = await axios.post(`${Base_Url}/admin-add-aboutus`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data for file upload
          }
        });

        if (response.status === 200) {
          // Handle success response
          // console.log('Member added successfully:', response.data);
          toast.success(' Added successfully');
          fetchData()
          closeButtonRef.current.click();
        } else {
          // console.error('Error adding member:', response.data);
          toast.error('Error adding member');
           fetchData();
        }
      } else {
        // console.log('All fields are required.');
        toast.error('All fields are required.');
      }
    } catch (error) {
      // console.error('Error adding member:', error);
      toast.error('Error adding member');
    }
  };






  // const softDeleteMember = async (id) => {
  //   try {
  //     // Show confirmation dialog
  //     const confirmDelete = window.confirm("Are you sure you want to delete this member?");

  //     if (confirmDelete) {
  //       const response = await axios.put(`${Base_Url}/admin-soft-delete/${id}`);

  //       if (response.status === 200) {
  //         // Filter out the deleted member from the local state
  //         const filteredMembers = teamMembers.filter(member => member.id !== id);
  //         setTeamMembers(filteredMembers);
  //         fetchData()
  //         toast.success(' Deleted Successfully');
  //       } else {
          // console.error('Error deleting member:', response.data);
  //       }
  //     }
  //   } catch (error) {
      // console.error('Error deleting member:', error);
  //   }
  // };

  const softDeleteMember = async (id) => {
    try {
      // Show confirmation dialog
      const confirmDelete = window.confirm("Are you sure you want to delete this member?");
      
      if (confirmDelete) {
        const deletedById =   userDataRedux; // Assuming you have a function to retrieve the ID of the user performing the deletion
        // console.log('Deleted By ID:', deletedById?.user_id); // Debugging statement
        
        const response = await axios.put(`${Base_Url}/admin-soft-delete/${id}`, {
          softdeleted_by: deletedById?.user_id
        });
  
        if (response.status === 200) {
          // Filter out the deleted member from the local state
          const filteredMembers = teamMembers.filter(member => member.id !== id);
          setTeamMembers(filteredMembers);
          fetchData();
          toast.success('Deleted Successfully');
        } else {
          // console.error('Error deleting member:', response.data);
        }
      }
    } catch (error) {
      // console.error('Error deleting member:', error);
    }
  };

  const closeButtonRef = useRef(null);
  // console.log(closeButtonRef)

  return (
    <>
      <div style={{ padding: "10px" }}>
        <div className="h1 text-center text-dark p-5 d-flex justify-content-center align-items-center">
          <FaUsers className="fa fa-users h1-text mb-0  mt-0  mr-3" style={{ color: "#C21717" }} aria-hidden="true" />
          <span className="ms-3" style={{ color: "#c21717" }}>ABOUT US</span>
        </div>

        <div className="container-fluid mainCOntent pb-lg-5" style={{ paddingBottom: "5rem" }}>

          {teamMembers.map((member, index) => {
            if (member.is_deleted) {
              return null; // Skip rendering soft-deleted members
            }
            // console.log(member)
            const imageURL = member.image_data ? member.image_data?.replace(/\\/g, '/').replace('uploads/', '') : null;

            // console.log(imageURL)
            const isCurrentSelected = selectedMemberIndex === index;
            return (
              <div className="box1" style={{ cursor: "pointer", position: "relative" }} key={index}>
                <div className="top-bar" style={{ background: "#C21717 !important" }}></div>
                <div className="top"></div>
                <div className="content">
                  {isCurrentSelected && previewImage ? (
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  ) : (
                    member.image_data ? (
                      <img src={imageURL} alt={member.name}
                        style={{
                          width: showMoreInfo && isCurrentSelected ? '100px' : '100%',
                          height: showMoreInfo && isCurrentSelected ? '100px' : 'auto'
                        }}
                      />
                    ) : (
                      <p>No image available</p>
                    )
                  )}

                  {selectedMemberIndex === index ? (
                    <>
                      <input
                        type="text"
                        style={{ padding: "8px 16px" }}
                        className="form-control mb-2"
                        value={tempName}
                        onChange={(e) => setTempName(e.target.value)}
                      />
                      <input
                        type="text"
                        style={{ padding: "8px 16px" }}
                        className="form-control mb-2"
                        value={tempRole}
                        onChange={(e) => setTempRole(e.target.value)}
                      />
                      <input
                        type="text"
                        style={{ padding: "8px 16px" }}
                        className="form-control mb-3"
                        value={tempMoreInfoLink}
                        onChange={(e) => setTempMoreInfoLink(e.target.value)}
                      />
                      {/* Use the icon for Upload */}
                      <label htmlFor="file-upload" className="mb-0 custom-file-upload d-block mb-3">
                        <FaUpload /> Upload
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />


                    </>
                  ) : (
                    <>
                      <strong>{member.name}</strong>
                      <p>{member.role}</p>
                    </>
                  )}
                </div>
                <div className="btn">
                  {
                    showMoreInfo ? "" : <a href={member.more_info_link} className=" d-flex justify-content-center align-items-center" target="_blank">
                      <FaEye className="fa fa-eye mr-1" aria-hidden="true"></FaEye>More Info...
                    </a>
                  }

                  {
                    userExists ? <>
                      {selectedMemberIndex === index ? (
                        <FaCheck
                          className="edit-icon text-success"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => saveChanges(index)} // Call saveChanges with index
                        />
                      ) : (
                        <FaEdit
                          className="edit-icon "
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEditClick(index)}
                        />
                      )}
                    </> : ""
                  }

                </div>
                {
                  userExists ? <div
                    className="delete-icon text-danger"
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => softDeleteMember(member.id)} // Call softDeleteMember with member ID
                  >
                    <FaTrash />
                  </div> : ""
                }


              </div>


            )
          })}


        </div>
        {
          userExists ? <div className="text-center d-flex justify-content-center mb-5">
            <div className="d-flex justify-content-center" style={{ width: '22%' }}>
              <button className="btn btn-success btn-sm mt-3 p-2 mr-5"  style={{width:"initial"}}  onClick={() => saveChanges(selectedMemberIndex)}>
                <span style={{fontSize:"12px"}}>Save Changes</span>
              </button>
              <button type="button" data-toggle="modal" style={{width:"initial"}} data-target="#exampleModal" className="btn btn-primary btn-sm mt-3 p-2" onClick={() => saveChanges(selectedMemberIndex)}>
                <span style={{fontSize:"12px"}}>
                Add Member
                </span>
              </button>
              
            </div>
          </div>
            : ""
        }

      </div>

      {/* bootstap form start */}

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
              <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Add Member</h5>
              <button ref={closeButtonRef} className="text-white close" type="button" data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <form>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="name">Name :</label>
                            <input
                              type="text"
                              className="p-2 form-control mt-2"
                              id="name"
                              name="name"
                              placeholder="Enter name"
                              value={formData2.name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="role">Role :</label>
                            <input
                              type="text"
                              className="p-2 form-control mt-2"
                              id="role"
                              name="role"
                              placeholder="Enter role"
                              value={formData2.role}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="moreInfoLink">More Info Link :  </label>
                            <input
                              type="text"
                              className="p-2 form-control mt-2"
                              id="moreInfoLink"
                              name="moreInfoLink"
                              placeholder="Enter more info link"
                              value={formData2.moreInfoLink}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="image">Upload Image:</label>
                            <input type="file" ref={fileInputRef} style={{ display: "none" }} className="form-control-file mt-2" id="image" name="image" onChange={handleImageUpload} />
                            <button type="button" className="btn btn-secondary mt-2" onClick={handleUploadButtonClick}>
                              Upload <FontAwesomeIcon icon={faUpload} className="ml-1" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <button type="button" className="btn btn-success mt-4 btn-block w-25" onClick={addMember}>Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <ToastContainer />
    </>
  );
};

export default AboutUs;



//     <div style={{ padding: "10px" }}>
//       <link
//         rel="stylesheet"
//         href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
//         integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
//         crossOrigin="anonymous"
//       />

//       {/* <h1 className="h1-text" style={{background:"#C21717 !important"}} >
//     US
//       </h1> */}
//       <div className="h1 text-center text-dark p-5 d-flex justify-content-center align-items-center">
//         {/* <FaUsers className="fa fa-users h1-text" style={{ color: "#C21717" }}/> */}
//         <FaUsers
//           className="fa fa-users h1-text mb-0  mt-0  mr-3"
//           style={{ color: "#C21717" }}
//           aria-hidden="true"
//         ></FaUsers>
//         <span className="ms-3" style={{ color: "#c21717" }}>
//           {" "}
//           ABOUT
//         </span>{" "}
//         US
//       </div>

//       <div className="container mainCOntent pb-lg-5">
//         {/* Team Member 1 */}
//         <div className="box1" style={{ cursor: "pointer" }}>
//           <div
//             className="top-bar"
//             style={{ background: "#C21717 !important" }}
//           ></div>
//           <div className="top">
//             {/* <i className="fa fa-check-circle" aria-hidden="true"></i>
//             <input type="checkbox"  classNameNaeclassName="p-2 heart-btn" id="heart-btn-1" />
//             <label className="mb-0" className="heart" htmlFor="heart-btn-1"></label> */}
//           </div>
//           <div className="content">
//             <img
//               // src="https://dos.iitd.ac.in/images/about/adi-mittal.jpg"
//               src={Aditya}
//               alt="aditya"
//             />
//             <strong>Prof. Aditya Mittal</strong>
//             <p>Dean, Student Affairs</p>
//           </div>
//           <div className="btn">
//             <a
//               href="https://web.iitd.ac.in/~amittal/"
//               className=" d-flex justify-content-center align-items-center"
//               target="blank"
//             >
//               <FaEye className="fa fa-eye mr-1" aria-hidden="true"></FaEye>More
//               Info...
//             </a>
//           </div>
//         </div>
//         <div className="box" style={{ cursor: "pointer" }}>
//           <div className="top-bar"></div>
//           <div className="top">
//             {/* <i className="fa fa-check-circle" aria-hidden="true"></i>
//             <input type="checkbox"  classNameNaeclassName="p-2 heart-btn" id="heart-btn-2" />
//             <label className="mb-0" className="heart" htmlFor="heart-btn-2"></label> */}
//           </div>
//           <div className="content">
//             <img
//               // src="https://dos.iitd.ac.in/images/about/b-k-pani.jpg"
// src={bkpani}
//               alt=""
//             />
//             <strong>Prof. B. K. Panigrahi</strong>
//             <p>Associate Dean, Hostel Management</p>
//           </div>
//           <div className="btn">
//             <a
//               href="https://ee.iitd.ac.in/faculty-profile/16"
//               className=" d-flex justify-content-center align-items-center"
//               target="blank"
//             >
//               <FaEye className="fa fa-eye mr-1" aria-hidden="true"></FaEye>More
//               Info...
//             </a>
//           </div>
//         </div>
//         <div className="box" style={{ cursor: "pointer" }}>
//           <div className="top-bar"></div>
//           <div className="top">
//             {/* <i className="fa fa-check-circle" aria-hidden="true"></i>
//             <input type="checkbox"  classNameNaeclassName="p-2 heart-btn" id="heart-btn-3" />
//             <label className="mb-0" className="heart" htmlFor="heart-btn-3"></label> */}
//           </div>
//           <div className="content">
//             <img
//             //  src="https://dos.iitd.ac.in/images/vivekanandan.jpg"
//              src={vivek}
//              alt="" />
//             <strong>Prof. Vivekanandan Perumal</strong>
//             <p>Associate Dean, Students Events</p>
//           </div>
//           <div className="btn">
//             <a
//               href="https://vperumal.wixsite.com/vivekanandan-perumal"
//               className=" d-flex justify-content-center align-items-center"
//               target="blank"
//             >
//               <FaEye className="fa fa-eye mr-1" aria-hidden="true"></FaEye>More
//               Info...
//             </a>
//           </div>
//         </div>
//         <div className="box" style={{ cursor: "pointer" }}>
//           <div className="top-bar"></div>
//           <div className="top">
//             {/* <i className="fa fa-check-circle" aria-hidden="true"></i> */}
//             {/* <input type="checkbox"  classNameNaeclassName="p-2 heart-btn" id="heart-btn-3" /> */}
//             {/* <label className="mb-0" className="heart" htmlFor="heart-btn-3"></label> */}
//           </div>
//           <div className="content">
//             <img
//               // src="https://dos.iitd.ac.in/images/about/tanushree.jpg"
//               src={tanushree}
//               alt=""
//             />
//             <strong>Prof. Tanusree Chakraborty</strong>
//             <p>Associate Dean, Student Welfare</p>
//           </div>
//           <div className="btn">
//             <a
//               href="https://web.iitd.ac.in/~tanusree/"
//               className=" d-flex justify-content-center align-items-center"
//               target="blank"
//             >
//               <FaEye className="fa fa-eye mr-1" aria-hidden="true"></FaEye>More
//               Info...
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>