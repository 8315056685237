import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import '../Components/jodit.css';
import axios from 'axios';
import { Base_Url} from "./config";

function ManageChairperson() {
  const editorRef = useRef(null);
  const [content, setContent] = useState();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${Base_Url}/chairperson-content`, {
        content: content, // Content entered in the editor
        // Other form data, if any
      });
      // console.log('Data saved successfully:', response.data);
    } catch (error) {
      // console.error('Error saving data:', error);
    }
  };

  // console.log(content)

  return (
    <div>
      <h2>Chairperson Details:-</h2>
      <JoditEditor
        ref={editorRef}
        value={content}
        config={{
          readonly: false, 
          uploader: {
            insertImageAsBase64URI: true, // Insert images as base64 URIs
            url: `${Base_Url}/chairperson-img`,

          },
        }}
        tabIndex={1}
        onBlur={(newContent) => setContent(newContent)}
      />
      <button onClick={handleSubmit}>Save</button>
    </div>
  );
}

export default ManageChairperson;