import React, { useEffect, useRef, useState } from "react";
import "./Footer.css";
import img1 from "../Assests/footerlogowhite.png"
import { FaMapMarkerAlt, FaPhoneSquare, FaEnvelope, FaEdit } from "react-icons/fa";
import axios from "axios";
import { Base_Url} from "./config";
import { useSelector } from "react-redux";
import { ImArrowUpRight } from "react-icons/im";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState('');
  const [editWhichPart, setEditWhichPart] = useState('');


  const userExists = useSelector((state) => state.user.userExists);
  const [edit, setEdit] = useState(userExists);


  // console.log(userExists)
  // console.log(edit)
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  const [dosInfo, setDosInfo] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  const [selectedPhone, setSelectedPhone] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Base_Url}/dosinfo`); // Assuming your server is running on the same host/port
      setDosInfo(response.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  // console.log(dosInfo)
  const closeButtonRef = useRef(null);


  // const handleEdit = (info) => {
  //   if (editWhichPart === "Address") {
  //     setSelectedAddress(info.address);
  //     setSelectedId(info.id)
  //   }
  //   else if (editWhichPart === "Phone") {
  //     setSelectedPhone(info.contact_no);
  //     setSelectedId(info.id)
  //   }
  //   else if (editWhichPart === "Email") {
  //     setSelectedEmail(info.email_id);
  //     setSelectedId(info.id)
  //   }
  // };

  const handleEdit = (info, part) => {
    setEditWhichPart(part); // Set the editWhichPart state when an edit action is triggered
    setSelectedId(info.id);
  
    if (part === "Address") {
      setSelectedAddress(info.address);
    } else if (part === "Phone") {
      setSelectedPhone(info.contact_no);
    } else if (part === "Email") {
      setSelectedEmail(info.email_id);
    }
  };
  


  // console.log(selectedAddress)
  // console.log(selectedEmail)
  // console.log(selectedId)
  // console.log(selectedPhone)
  // console.log(dosInfo)

  const handleUpdateSave = () => {
    // Construct the data object to be updated
    const dataToUpdate = {};
  
    // Update the field based on editWhichPart
    if (editWhichPart === "Address") {
      dataToUpdate.address = selectedAddress;
      dataToUpdate.contact_no =dosInfo[0].contact_no ;
      dataToUpdate.email_id =dosInfo[0].email_id ;
    } else if (editWhichPart === "Phone") {
      dataToUpdate.contact_no = selectedPhone;
      dataToUpdate.address = dosInfo[0].address ;
      dataToUpdate.email_id =dosInfo[0].email_id ;
    } else if (editWhichPart === "Email") {
      dataToUpdate.email_id = selectedEmail;
      dataToUpdate.address = dosInfo[0].address ;
      dataToUpdate.contact_no =dosInfo[0].contact_no ;
    }
  
    // Check if there are fields to update
    if (Object.keys(dataToUpdate).length === 0) {
      // console.log("No fields to update");
      return;
    }
  
    axios.put(`${Base_Url}/dosinfo-edit/${selectedId}`, dataToUpdate)
      .then(response => {
        // console.log('Dosinfo updated successfully');
        fetchData()
        closeButtonRef.current.click();
        // Handle success as needed, e.g., show a success message
      })
      .catch(error => {
        // console.error('Error updating dosinfo:', error);
        // Handle error, e.g., show an error message to the user
      });
  }
  

  


  return (
    <div>

      <div className="modal fade" id="exampleModal5" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
          <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
            <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Edit {editWhichPart}</h5>
            <button className="text-white close" type="button" ref={closeButtonRef}  data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>


            <div className="modal-body">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 align-items-center">
                              {
                                editWhichPart === "Address" ?
                                  <div className="mb-3 d-flex justify-content-center align-items-center">
                                    <textarea onChange={(e) => setSelectedAddress(e.target.value)} value={selectedAddress} className="form-control" id="exampleTextarea" rows="5"></textarea>
                                  </div>
                                  :
                                  editWhichPart === "Phone" ?
                                    <input type="text" value={selectedPhone} onChange={(e) => setSelectedPhone(e.target.value)} className="form-control" id="exampleInput" placeholder="Enter Phone" />
                                    :
                                    <input type="email" value={selectedEmail} onChange={(e) => setSelectedEmail(e.target.value)} className="form-control" id="exampleInput" placeholder="Enter Email" />
                              }


                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-right">
                        <button className="btn" style={{ background: "#c21717", color: "white" }}
                        onClick={handleUpdateSave}
                        >Save Changes</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="upperfooter">
        <section class="contact-box contact-box-top theme-bg">
          <div class="container">
            <div class="row pt-4 pb-4">
              <div class="col-md-4 mb-4 mb-md-0">
                <div class="contact-box text-start">
                  <div class="contact-icon">
                    <FaMapMarkerAlt className="text-white" />
                  </div>
                  <div class="contact-info">
                    <h5 class="text-white h5 contact-title font-weight-bold">
                      ADDRESS
                    </h5>

                    {/* Dean of Student Affairs, Indian Institute of Technology Delhi, Hauz Khas, New Delhi - 110016 */}
                    {
                      dosInfo?.map((info) => {
                        return (
                          <>
                            <span class="text-white contact-detail mr-2 ">  {info?.address}  </span>
                            {/* <FaEdit color="white" type="button" onClick={() => { handleEdit(info); setEditWhichPart("Address") }} data-toggle="modal" data-target="#exampleModal5" /> */}
                         {
                          userExists ?  <FaEdit color="white" type="button" onClick={() => handleEdit(info, "Address")} data-toggle="modal" data-target="#exampleModal5" /> :""
                         }
                         
                           
                          </>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-4 mb-md-0">
                <div class="contact-box text-start">
                  <div class="contact-icon">
                    <FaPhoneSquare className="text-white" />
                  </div>
                  <div class="contact-info">
                    <h5 class="text-white h5 contact-title font-weight-bold">
                      PHONE
                    </h5>
                    <a class="text-white contact-detail">
                      {
                        dosInfo?.map((info) => {
                          return (
                            <>
                              <span class="text-white contact-detail mr-2">  {info?.contact_no}  </span>
                              {/* <FaEdit color="white" type="button" onClick={() => { handleEdit(info); setEditWhichPart("Phone") }} data-toggle="modal" data-target="#exampleModal5" /> */}
                             
                             {
                               userExists ? 
                               <FaEdit color="white" type="button" onClick={() => handleEdit(info, "Phone")} data-toggle="modal" data-target="#exampleModal5" /> :""
                             }
                            </>
                          )
                        })
                      }
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="contact-box text-start">
                  <div class="contact-icon">
                    <FaEnvelope className="text-white" />
                  </div>
                  <div class="contact-info">
                    <h5 class="text-white h5 contact-title font-weight-bold">
                      EMAIL
                    </h5>
                    <span class="text-white contact-detail">
                      {
                        dosInfo?.map((info) => {
                          return (
                            <>
                              <span class="text-white contact-detail mr-2">  {info?.email_id}  </span>

                              {/* <FaEdit color="white" type="button" onClick={() => { handleEdit(info); setEditWhichPart("Email") }} data-toggle="modal" data-target="#exampleModal5" /> */}
                              {
                                userExists ? <FaEdit color="white" type="button" onClick={() => handleEdit(info, "Email")} data-toggle="modal" data-target="#exampleModal5" /> :""
                              }
                             
                            </>
                          )
                        })
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      <div>
        <div class="footer page-section-pt black-bg">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6 sm-mb-30">
                <div class="footer-logo">
                  <img
                    id="logo-footer"
                    class="mb-20"
                    // src="https://bioschool.iitd.ac.in/public/frontend/images/logo.png"
                    src={img1}
                    alt=""
                  />
                </div>
                <div class="social-icons color-hover"></div>
              </div>
              {/* <div class="col-lg-2 col-md-6 col-sm-6 sm-mb-30">
                <div class="footer-useful-link footer-hedding">
                  <h6 class="text-white mb-2 mt-10 text-uppercase" >
                    Navigation
                  </h6>
                  <div style={{ background: "#c50227", height: "2px", width: "72%" }}></div>
                  <ul className="ps-0">
                    <li className="li1 mt-3">
                      <a
                        className="li1"
                        // href="https://bioschool.iitd.ac.in/researches"
                        href="#"
                      >
                        Research
                      </a>{" "}
                    </li>
                    <li className="li1">
                      <a
                        className="li1"
                        // href="https://bioschool.iitd.ac.in/faculties"
                        href="#"
                      >
                        Faculty
                      </a>{" "}
                    </li>
                    <li className="li1">
                      <a
                        className="li1"
                        // href="https://bioschool.iitd.ac.in/phd"
                        href="#"
                      >
                        PhD Programme
                      </a>{" "}
                    </li>
                    <li className="li1">
                      <a
                        className="li1"
                        // href="https://bioschool.iitd.ac.in/msresearch"
                        href="#"
                      >
                        MS Research Programme
                      </a>{" "}
                    </li>
                    <li className="li1">
                      <a
                        className="li1"
                        href="#"
                      >
                        Announcements
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div> */}

              <div class="col-lg-4 col-md-6 col-sm-6">
                <h6 class="text-white mb-2 mt-10 text-uppercase">
                  Contact Us
                </h6>
                <div style={{ background: "#c50227", height: "2px", width: "34%" }}></div>
                <ul class="addresss-info p-0">
                  <ul class="addresss-info p-0">
                    <li className="d-flex align-items-start li1 mt-2">
                      <FaMapMarkerAlt className="me-3 mt-2  icons" />
                      <a class="mr-0 ">
                        {" "}
                        <p className="li1">
                          {
                            dosInfo?.map((info) => {
                              return (
                                <>
                                  <span >  {info?.address}  </span>
                                </>
                              )
                            })
                          }
                        </p>
                      </a>
                    </li>
                    <li className="li1">
                      <FaPhoneSquare className="me-3 li1 icons" />
                      <a class="mr-0 li1">
                        {" "}
                        {
                          dosInfo?.map((info) => {
                            return (
                              <>
                                <span >  {info?.contact_no}  </span>
                              </>
                            )
                          })
                        }
                      </a>
                    </li>
                    <li className="li1 " style={{ cursor: "pointer" }}>
                      <FaEnvelope className="me-3 li1 icons" />
                      {
                        dosInfo?.map((info) => {
                          return (
                            <>
                              <span >  {info?.email_id}  </span>
                            </>
                          )
                        })
                      }
                    </li>
                  </ul>
                </ul>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <h6 class="text-white mb-2 mt-10 text-uppercase">
                 Administrative Assistance
                </h6>
                <div style={{ background: "#c50227", height: "2px", width: "34%" }}></div>
                <ul class="addresss-info p-0">
                  <ul class="addresss-info p-0">
                    <li className="d-flex align-items-start li1 mt-2">
                      <ImArrowUpRight className="me-3 mt-2  icons" />
                      <a class="mr-0 "
                      //  href="https://home.iitd.ac.in/administration.php/content/student-affairs-section"
                       target="_blank">
                        {" "}
                        <p className="li1">
                          Student Affairs Section
                        </p>
                      </a>
                    </li>
                    <li className="li1">
                    <FaMapMarkerAlt className="me-3 li1 icons" />
                      <a class="mr-0 ">
                        <span > MS-207/C-9 & 10 </span>
                      </a>
                    </li>
                    <li className="li1 " style={{ cursor: "pointer" }}>
                    <FaPhoneSquare className="me-3 li1 icons" />
                      <a class="mr-0 ">
                        <span >  (91)-11-2659-1747 </span>
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div
              className="mb-2"
              style={{ height: "1px", background: "gray" }}
            ></div>
            <div class="footer-widget mt-20">
              <div class="row">
                <div className="col-md-6 text-left">
                  <p className="mt-15">
                    © Copyright <span>{currentYear ? currentYear : "2024-25"}</span>,{' '}
                    <span className="text-white h5">Dean, Student Affairs, IIT Delhi</span>
                  </p>
                </div>

                <div class="col-md-6 text-right">
                  <p class="mt-15 h5">
                    {" "}
                    Developed &amp; Maintained by:{" "}
                    <a
                      href="http://csc.iitd.ac.in/"
                      target="_blank"
                      class="text-white h5"
                    >
                      CSC, IIT Delhi
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
