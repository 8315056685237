import React, { useEffect, useRef, useState } from "react";
import "./Fund.css"; // Import your CSS file
import { FaEdit, FaFilePdf, FaPlus, FaPlusCircle, FaTrash, FaUpload } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Base_Url } from "./config";

const YourComponent = () => {

  const [processFlows, setProcessFlows] = useState([]);
  const [claimForms, setClaimForms] = useState([]);
  const [distressData, setDistressData] = useState([]);
  const [BENEVOLENTFUNDSCHEMEPDF, setBENEVOLENTFUNDSCHEMEPDF] = useState([]);
  const [medicalInsurancePolicy, setMedicalInsurancePolicy] = useState([]);
  const [mIpolicyRisk, setMIpolicyRisk] = useState([]);
  const [title, setTitle] = useState();
  const [conditionIs, setConditionIs] = useState();
  const [currentId, setCurrentId] = useState();
  const [contacts_medical, setContacts_medical] = useState([]);
  const [fetchStudentdistressfunds, setFetchStudentdistressfund] = useState();
  const [benevolentfundscheme, setBenevolentfundscheme] = useState();
  const [studentStressPdfInfo, setStudentStressPdfInfo] = useState();
  const [studentStressPdfPath, setStudentStressPdfPath] = useState();
  const [studentdistresspdfId, setStudentdistresspdfId] = useState();
  const [whichPdfEdit, setWhichPdfEdit] = useState();

  const userExists = useSelector((state) => state.user.userExists);

  // const [isEdit, setEdit] = useState(userExists)

  useEffect(() => {
    fetchProcessFlows(); // Fetch contact person data
    fetchMedicalInsuranceClaimForms()
    fetchStudentDistressFundSchemePdf()
    fetchBFSChemePdf()
    fetchMedicalInsurancePolicyRisk()
    fetchStudentdistressfund()
    fetchBenevolentfundscheme()
    fetchContacts();
  }, []);




  const fetchProcessFlows = async () => {
    try {
      const response = await axios.get(`${Base_Url}/processFlows`);
      setProcessFlows(response.data);
    } catch (error) {
      // console.error("Error fetching process flows:", error);
    }
  };
  const fetchBFSChemePdf = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/benevolent_fund_scheme_pdf`
      );
      setBENEVOLENTFUNDSCHEMEPDF(response.data);
    } catch (error) {
      // console.error("Error fetching process flows:", error);
    }
  };

  const fetchMedicalInsuranceClaimForms = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/medicalInsuranceclaimform`
      );
      setClaimForms(response.data);
    } catch (error) {
      // console.error("Error fetching medical insurance claim forms:", error);
    }
  };

  const fetchStudentDistressFundSchemePdf = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/student_distress_fund_scheme_pdf_api`
      );
      setDistressData(response.data);
    } catch (error) {
      // console.error("Error fetching process flows:", error);
    }
  };

  const fetchBenevolentfundscheme = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/benevolent_fund_scheme`
      );
      setBenevolentfundscheme(response.data);
    } catch (error) {
      // console.error("Error fetching process flows:", error);
    }
  };
  const fetchMedicalInsurancePolicyRisk = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/medical_insurance_policy_risk`
      );
      setMedicalInsurancePolicy(response.data);
    } catch (error) {
      // console.error("Error fetching process flows:", error);
    }
  };


  const uniqueTitles = Array.from(
    new Set(medicalInsurancePolicy?.map((policy) => policy.info))
  );

  // console.log(uniqueTitles)
  // console.log(medicalInsurancePolicy)
  // console.log(mIpolicyRisk)

  const closeButtonRef = useRef(null);
  const closeButtonRef2 = useRef(null);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Base_Url}/medical_insurance_policy_risk`, { info: title, content: mIpolicyRisk });
      if (response) {
        toast.success("Added Successfully")
        fetchMedicalInsurancePolicyRisk()
        closeButtonRef.current.click()

      }
    } catch (error) {
      // console.error('Error inserting medical insurance:', error);
      toast.error('Error: Unable to Insert Data');
    }
  };
  const add_contact = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Base_Url}/medical-contacts`, { info: title, content: mIpolicyRisk });
      if (response) {
        toast.success("Added Successfully")
        fetchContacts()
        closeButtonRef.current.click()

      }
    } catch (error) {
      // console.error('Error inserting medical insurance:', error);
      toast.error('Error: Unable to Insert Data');
    }
  };
  const addStudentdistressfund = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Base_Url}/student-distress-fund`, { info: null, content: mIpolicyRisk });
      if (response) {
        toast.success("Added Successfully")
        fetchStudentdistressfund()
        closeButtonRef.current.click()

      }
    } catch (error) {
      // console.error('Error inserting medical insurance:', error);
      toast.error('Error: Unable to Insert Data');
    }
  };
  const addBenevolentfundscheme = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Base_Url}/benevolent_fund_scheme`, { info: null, content: mIpolicyRisk });
      if (response) {
        toast.success("Added Successfully")
        fetchBenevolentfundscheme()
        closeButtonRef.current.click()

      }
    } catch (error) {
      // console.error('Error inserting medical insurance:', error);
      toast.error('Error: Unable to Insert Data');
    }
  };



  const deletePolicyCoverRisk = async (id) => {
    // Show confirmation dialog to the user
    if (!window.confirm('Are you sure you want to delete this medical insurance policy risk?')) {
      return; // If user cancels, do nothing
    }

    try {
      const response = await axios.delete(`${Base_Url}/medical_insurance_policy_risk/${id}`);
      toast.success(response.data);
      fetchMedicalInsurancePolicyRisk();
    } catch (error) {
      // console.error('Error deleting medical insurance:', error);
      toast.error('Error: Unable to delete medical insurance');
    }
  };
  const deleteContactMI = async (id) => {
    // Show confirmation dialog to the user
    if (!window.confirm('Are you sure you want to delete this medical insurance policy risk?')) {
      return; // If user cancels, do nothing
    }

    try {
      const response = await axios.delete(`${Base_Url}/medical-contacts/${id}`);
      toast.success(response.data);
      fetchContacts()
    } catch (error) {
      // console.error('Error deleting medical insurance:', error);
      toast.error('Error: Unable to delete medical insurance');
    }
  };
  const deleteStudentDistressFund = async (id) => {
    // Show confirmation dialog to the user
    if (!window.confirm('Are you sure you want to delete this medical insurance policy risk?')) {
      return; // If user cancels, do nothing
    }

    try {
      const response = await axios.delete(`${Base_Url}/student-distress-fund/${id}`);
      toast.success(response.data);
      fetchStudentdistressfund()
    } catch (error) {
      // console.error('Error deleting medical insurance:', error);
      toast.error('Error: Unable to delete medical insurance');
    }
  };
  const deleteBenevolent = async (id) => {
    // Show confirmation dialog to the user
    if (!window.confirm('Are you sure you want to delete this medical insurance policy risk?')) {
      return; // If user cancels, do nothing
    }

    try {
      const response = await axios.delete(`${Base_Url}/benevolent_fund_scheme/${id}`);
      toast.success(response.data);
      fetchBenevolentfundscheme()
    } catch (error) {
      // console.error('Error deleting medical insurance:', error);
      toast.error('Error: Unable to delete medical insurance');
    }
  };

  const [addEditToggle, setAddEditToggle] = useState()

  const handleCurrentEdit = (elem, whichEdit) => {
    if (whichEdit == "policy") {
      setMIpolicyRisk(elem?.content)
      setAddEditToggle("Edit ")
      setConditionIs("Edit")
      setCurrentId(elem.id)
      setTitle(elem.info)
    }
    else if (whichEdit == "mi_contact") {
      setConditionIs("mi_contact_edit")
      setMIpolicyRisk(elem?.content)
      setAddEditToggle("mi_contact_edit")
      setConditionIs("mi_contact_edit")
      setCurrentId(elem.id)
      setTitle(elem.info)
    }
    else if (whichEdit == "student_distress") {
      setConditionIs("student_distress_edit")
      setMIpolicyRisk(elem?.content)
      setAddEditToggle("student_distress_edit")
      setConditionIs("student_distress_edit")
      setCurrentId(elem.id)
      setTitle(elem.info)
    }
    else if (whichEdit == "benevolent_fund_scheme") {
      setConditionIs("benevolent_fund_scheme_edit")
      setMIpolicyRisk(elem?.content)
      setAddEditToggle("benevolent fund_scheme_edit")
      setConditionIs("benevolent_fund_scheme_edit")
      setCurrentId(elem.id)
      setTitle(elem.info)
    }
  }


  // console.log(title)

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`${Base_Url}/medical_insurance_policy_risk/${currentId}`, {
        info: title,
        content: mIpolicyRisk
      });
      toast.success(response.data);
      fetchMedicalInsurancePolicyRisk();
      closeButtonRef.current.click()

    } catch (error) {
      // console.error('Error updating medical insurance:', error);
      toast.error('Error: Unable to update medical insurance');
    }
  };
  const handleContact_update = async () => {
    try {
      const response = await axios.put(`${Base_Url}/medical-contacts/${currentId}`, {
        info: title,
        content: mIpolicyRisk
      });
      toast.success(response.data);
      fetchContacts();
      closeButtonRef.current.click()

    } catch (error) {
      // console.error('Error updating medical insurance:', error);
      toast.error('Error: Unable to update medical insurance');
    }
  };
  const editBenevolentfundscheme = async () => {
    try {
      const response = await axios.put(`${Base_Url}/benevolent_fund_scheme/${currentId}`, {
        info: title,
        content: mIpolicyRisk
      });
      toast.success("Updated Succesfully");
      fetchBenevolentfundscheme();
      closeButtonRef.current.click()

    } catch (error) {
      // console.error('Error updating medical insurance:', error);
      toast.error('Error: Unable to update medical insurance');
    }
  };
  const editStudentdistressfund = async () => {
    try {
      const response = await axios.put(`${Base_Url}/student-distress-fund/${currentId}`, {
        info: title,
        content: mIpolicyRisk
      });
      toast.success("Updated Succesfully");
      fetchStudentdistressfund()
      closeButtonRef.current.click()
    } catch (error) {
      // console.error('Error updating medical insurance:', error);
      toast.error('Error: Unable to update medical insurance');
    }
  };





  const fetchContacts = async () => {
    try {
      const response = await axios.get(`${Base_Url}/medical-contacts`); // Assuming the endpoint is /contacts
      setContacts_medical(response.data);
    } catch (error) {
      // console.error("Error fetching contacts:", error);
    }
  };

  const fetchStudentdistressfund = async () => {
    try {
      const response = await axios.get(`${Base_Url}/student-distress-fund`); // Assuming the endpoint is /contacts
      setFetchStudentdistressfund(response.data);
    } catch (error) {
      // console.error("Error fetching contacts:", error);
    }
  };

  const resetState = () => {
    // Clear state variables
    setTitle();
    setMIpolicyRisk();
    setAddEditToggle();
    setConditionIs();
    setCurrentId();
    // Trigger click on close button to close the modal

  };


  const pdfEdit = (elem) => {
    setStudentStressPdfInfo(elem.info)
    if (whichPdfEdit == "benevolent") {
      setStudentStressPdfPath(elem.pdf_link)
    }
    else {
      setStudentStressPdfPath(elem.pdf_path)
    }
    setStudentdistresspdfId(elem.id)
  }

  // console.log(studentStressPdfInfo)
  // console.log(studentStressPdfPath)
  // console.log(distressData)

  const fileInputRef = useRef(null);


  const handleFileInputChange = (e) => {
    setStudentStressPdfPath(e.target.files[0]);
  };

  const handleUploadIconClick = () => {
    fileInputRef.current.click();
  };

  const handleUpdatePDF = async () => {
    try {
      const formData = new FormData();
      formData.append('file', studentStressPdfPath); // Append the file object, not just the path

      const response = await axios.put(`${Base_Url}/student_distress_fund_scheme_pdf_api/${studentdistresspdfId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      closeButtonRef2.current.click()
      toast.success('File uploaded successfully:', response.data);
      fetchStudentDistressFundSchemePdf();
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error here
    }
  };

  const handleUpdatePFPDF = async () => {
    try {
      const formData = new FormData();
      formData.append('file', studentStressPdfPath); // Append the file object, not just the path

      const response = await axios.put(`${Base_Url}/process-flow-empCode_pdf/${studentdistresspdfId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      closeButtonRef2.current.click()
      toast.success('File uploaded successfully:', response.data);
      fetchProcessFlows();
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error here
    }
  };
  const handleUpdatemedicalPdf = async () => {
    try {
      const formData = new FormData();
      formData.append('file', studentStressPdfPath); // Append the file object, not just the path

      const response = await axios.put(`${Base_Url}/medical_claim_pdf/${studentdistresspdfId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      closeButtonRef2.current.click()
      toast.success('File uploaded successfully:', response.data);
      fetchMedicalInsuranceClaimForms();
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error here
    }
  };
  const handleBenevolentPFPDF = async () => {
    try {
      const formData = new FormData();
      formData.append('file', studentStressPdfPath); // Append the file object, not just the path

      const response = await axios.put(`${Base_Url}/benevolent_fund_scheme_pdf/${studentdistresspdfId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      closeButtonRef2.current.click()
      toast.success('File uploaded successfully:', response.data);
      fetchBFSChemePdf();
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error here
    }
  };




  // console.log(studentStressPdfPath)

  function extractFileName(filePath) {
    if (typeof filePath === 'string') {
      // If filePath is a string, assume it's a file path
      const parts = filePath.split('\\');
      return parts.pop();
    } else if (filePath instanceof File) {
      // If filePath is a File object, return its name property
      return filePath.name;
    } else {
      // Handle other cases where filePath is neither a string nor a File object
      return 'Unknown';
    }
  }





  return (
    <>
      <div className="modal fade" id="exampleModal6" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>

              <h6 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto", fontSize: "15px", color: "#fff" }}>{addEditToggle} :
                {/* {editWhichPart} */}
              </h6>
              <button className="text-white close" type="button" ref={closeButtonRef} data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>


            <div className="modal-body">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 align-items-center">
                              <div className="mb-3 d-flex justify-content-center align-items-center">
                                <textarea
                                  onChange={(e) => setMIpolicyRisk(e.target.value)} placeholder="Enter Here..." value={mIpolicyRisk}
                                  className="form-control" id="exampleTextarea" rows="2"></textarea>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-right">
                        {
                          conditionIs === "Edit" ? (
                            <button
                              className="btn"
                              style={{ background: "#c21717", color: "white" }}
                              onClick={handleUpdate}
                            >
                              Update Changes
                            </button>
                          ) : conditionIs === "mi_contact_edit" ? (
                            <button
                              className="btn"
                              style={{ background: "#c21717", color: "white" }}
                              onClick={handleContact_update}
                            >
                              Contact Updates
                            </button>
                          ) :
                            conditionIs === "Add" ? (
                              <button
                                className="btn"
                                style={{ background: "#c21717", color: "white" }}
                                onClick={handleSubmit}
                              >
                                Save Changes
                              </button>
                            ) :
                              conditionIs === "Add_Student_distress_fund" ? (
                                <button
                                  className="btn"
                                  style={{ background: "#c21717", color: "white" }}
                                  onClick={addStudentdistressfund}
                                >
                                  Add
                                </button>
                              )
                                :
                                conditionIs === "student_distress_edit" ? (
                                  <button
                                    className="btn"
                                    style={{ background: "#c21717", color: "white" }}
                                    onClick={editStudentdistressfund}
                                  >
                                    Edit Student Distress Edit
                                  </button>
                                )
                                  :
                                  conditionIs === "benevolent_fund_scheme_edit" ? (
                                    <button
                                      className="btn"
                                      style={{ background: "#c21717", color: "white" }}
                                      onClick={editBenevolentfundscheme}
                                    >
                                      Edit Benevolent  Fund Scheme
                                    </button>
                                  )
                                    :
                                    conditionIs === "Add_Benevolent_fund_scheme" ? (
                                      <button
                                        className="btn"
                                        style={{ background: "#c21717", color: "white" }}
                                        onClick={addBenevolentfundscheme}
                                      >
                                        Add Benevolent  Fund Scheme
                                      </button>
                                    )
                                      :
                                      (
                                        <button
                                          className="btn Add_contact_mi"
                                          style={{ background: "#c21717", color: "white" }}
                                          onClick={add_contact}
                                        >
                                          Add Contact
                                        </button>
                                      )
                        }


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal7" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
              <h6 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto", fontSize: "15px", color: "#fff" }}> Edit :</h6>
              <button className="text-white close" type="button" ref={closeButtonRef2} data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body p-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12 p-0">
                    <div className="card">
                      <div className="card-body">
                        <form>
                          {/* <div className="form-group">
                            <label htmlFor="infoInput">Information:</label>
                            <textarea
                              id="infoInput"
                              className="form-control"
                              rows="3"
                              placeholder="Enter Info..."
                              onChange={(e) => setStudentStressPdfInfo(e.target.value)}
                              value={studentStressPdfInfo}
                            ></textarea>
                          </div> */}
                          <div className="form-group">
                            <label htmlFor="fileInput">Update PDF:
                              {studentStressPdfPath ? extractFileName(studentStressPdfPath) : studentStressPdfPath}
                            </label>
                            <div className="input-group w-100">
                              <div className="custom-file d-none">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="fileInput"
                                  ref={fileInputRef}
                                  onChange={handleFileInputChange}
                                  style={{ display: 'none' }}
                                />

                              </div>
                              <div className="input-group-append w-100 ">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={handleUploadIconClick}
                                  style={{ background: "#6c757d" }}
                                >
                                  <FaUpload color="white" />
                                </button>

                              </div>
                              <div className="input-group-append w-100">
                                {whichPdfEdit === "studentDistress" ? (
                                  <button
                                    className="btn btn-outline-secondary text-white"
                                    type="button"
                                    onClick={handleUpdatePDF}
                                    style={{ background: "#c21717" }}
                                  >
                                    Update
                                  </button>
                                ) : whichPdfEdit === "processFlows" ? (
                                  <button
                                    className="btn btn-outline-secondary text-white"
                                    type="button"
                                    onClick={handleUpdatePFPDF}
                                    style={{ background: "#c21717" }}
                                  >
                                    Update
                                  </button>
                                ) : whichPdfEdit === "benevolent" ? (
                                  <button
                                    className="btn btn-outline-secondary text-white"
                                    type="button"
                                    onClick={handleBenevolentPFPDF}
                                    style={{ background: "#c21717" }}
                                  >
                                    Update
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-outline-secondary text-white"
                                    type="button"
                                    onClick={handleUpdatemedicalPdf}
                                    style={{ background: "#c21717" }}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>

                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="pageCOnatianer d-flex justify-content-center">
        <div className="page">
          <div id="terms-and-conditions">
            <div className="h1 mb-4 mt-4 text-dark">
              <span style={{ color: "#c21717" }}> FUNDS & MEDICAL</span>{" "}
              <span>INSURANCE</span>
              <hr
                className="hr"
                style={{
                  borderColor: "#c21717",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
            </div>
            <b
              className="highlighted-text w-100 "
              style={{ marginBottom: "10px" }}
            >
              MEDICAL INSURANCE
            </b>

            <ul>
              {
                uniqueTitles?.map((info) => {
                  return (
                    <>
                      <li>
                        <div className="risk-statement d-flex ">

                          <br />
                          <b className="lead mr-lg-3 mr-2 text-uppercase mt-2 d-flex ">
                            {info}
                            {
                              userExists ? <FaPlusCircle style={{ fontSize: "18px" }} onClick={() => { setTitle(info); setAddEditToggle("Add THE POLICY COVERS THE STUDENTS FOR THE FOLLOWING RISKS"); setConditionIs("Add") }} color="#c21717" type="button" data-toggle="modal" data-target="#exampleModal6" /> : ""
                            }

                          </b>
                        </div>
                        <ul>
                          {
                            medicalInsurancePolicy?.map((elem) => {
                              return (
                                <>
                                  <span className="d-flex align-items-center">
                                    <li className="custom-list-item d-flex m-2">
                                      {
                                        elem.content
                                      }
                                    </li>
                                    {
                                      userExists ? <> <FaEdit className="mr-2 ml-1 text-primary" onClick={() => handleCurrentEdit(elem, "policy")} type="button" data-toggle="modal" data-target="#exampleModal6" /> <FaTrash onClick={() => { deletePolicyCoverRisk(elem.id) }} className="mr-2 ml-1 text-danger" /> </> : ""
                                    }

                                  </span>
                                </>
                              )
                            })
                          }

                        </ul>
                      </li>

                    </>
                  )
                })
              }

            </ul>

            <div className="risk-statement">
              <b className="lead mr-lg-3 mr-2 text-uppercase mt-2">CONTACT PERSON:</b>
              {
                userExists ? <FaPlusCircle style={{ fontSize: "18px" }} onClick={() => { setTitle("CONTACT PERSON:"); setAddEditToggle("Add contact Person"); setConditionIs("Add_contact_mi") }} color="#c21717" type="button" data-toggle="modal" data-target="#exampleModal6" /> : ""
              }

              <ul>
                {contacts_medical?.map((contact) => (
                  <li key={contact.id}>
                    <ul className="d-flex align-items-center">
                      <li className="custom-list-item d-flex mr-2 mt-0">{contact.content}</li>
                      {
                        userExists ? <>
                          <FaEdit className="mr-2 ml-1 text-primary" onClick={() => handleCurrentEdit(contact, "mi_contact")} data-toggle="modal" data-target="#exampleModal6" /> <FaTrash onClick={() => { deleteContactMI(contact.id) }} className="mr-2 ml-1 text-danger" />
                        </> : ""
                      }
                    </ul>
                  </li>
                ))}
              </ul>
            </div>




            <div className="claim-section">
              {processFlows?.map((elem) => {
                return (
                  <>
                    <a
                      href={`${Base_Url}/${elem?.pdf_path
                        .replace(/\\/g, "/")
                        .replace("uploads/", "")}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {elem.info}
                    </a>
                    {
                      userExists ? <FaEdit className="mr-2 ml-1 text-warning"
                        onClick={() => { pdfEdit(elem); setWhichPdfEdit("processFlows") }}
                        type="button" data-toggle="modal" data-target="#exampleModal7" /> : ""
                    }

                    <br />
                    <br />
                  </>
                );
              })}
              <div className="claim-links" style={{ marginTop: "15px" }}>
                <p>
                  <strong style={{ fontSize: "15px" }}>You can check the list of hospitals </strong>
                  <a
                    className="ms-1 here"
                    href="https://safewaytpa.in/NetworkHospital.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontSize: "14px" }}
                  >
                    here
                  </a>
                  .
                </p>
                {claimForms?.map((elem) => {
                  return (
                    <>
                      <p className="mb-1">
                        <strong style={{ fontSize: "15px" }}>{elem.info}</strong>
                        <a
                          className="ms-1"
                          href={`${Base_Url}/${elem?.pdf_path
                            ?.replace(/\\/g, "/")
                            .replace("uploads/", "")}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="pdf-icon" style={{ fontSize: "14px" }}>
                            Download Claim Form
                          </span>
                          <FaFilePdf />
                        </a>
                        {
                          userExists ? <FaEdit className="mr-2 ml-1 text-warning"
                            onClick={() => { pdfEdit(elem); setWhichPdfEdit("medical") }}
                            type="button" data-toggle="modal" data-target="#exampleModal7" /> : ""
                        }

                      </p>
                    </>
                  );
                })}
              </div>
            </div>
            <ul>
              <li>
                <div className="risk-statement d-flex ">
                  <b
                    className="text-uppercase highlighted-text w-100 d-flex  justify-content-between "
                    style={{ marginBottom: "10px" }}
                  >
                    Student Distress Fund
                    {
                      userExists ?
                        <FaPlusCircle style={{ fontSize: "18px", color: "white" }} onClick={() => { setTitle("CONTACT PERSON:"); setAddEditToggle("Add Student distress fund"); setConditionIs("Add_Student_distress_fund") }} color="#c21717" type="button" data-toggle="modal" data-target="#exampleModal6" />
                        : ""
                    }
                  </b>

                  <br />
                  {/* <b className="lead mr-lg-3 mr-2 text-uppercase mt-2">
                  The Policy covers the students for the following Risks:
                </b> */}
                </div>
                <ul>
                  <li>
                    <ul>
                      {
                        fetchStudentdistressfunds?.map((elem) => {
                          return (
                            <>
                              <div className="d-flex">

                                <li className="custom-list-item d-flex " style={{ width: "95%" }}>
                                  {elem.content}
                                </li>
                                {
                                  userExists ? <>
                                    <FaEdit className="mr-2 ml-1 text-primary" onClick={() => handleCurrentEdit(elem, "student_distress")} data-toggle="modal" data-target="#exampleModal6" /> <FaTrash onClick={() => { deleteStudentDistressFund(elem.id) }} className="mr-2 ml-1 text-danger" />
                                  </> : ""
                                }
                              </div>
                            </>
                          )
                        })
                      }
                      {" "}
                      <br />
                      {distressData && distressData.length > 0 && distressData?.map((elem) => {
                        return (
                          <>
                            <p className="mb-1">
                              <span
                                className="custom-list-item moreinformation"
                                style={{ color: "#4d4d4e", fontSize: "14px" }}
                              >
                                {elem.info}
                              </span>
                              <a
                                className="ms-1"
                                href={`${Base_Url}/${elem?.pdf_path
                                  ?.replace(/\\/g, "/")
                                  .replace("uploads/", "")}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="pdf-icon " style={{ fontSize: "14px" }}>
                                  Student Distress Fund Scheme
                                </span>
                                <FaFilePdf />
                              </a>
                              {
                                userExists ? <FaEdit className="mr-2 ml-1 text-warning"
                                  onClick={() => { pdfEdit(elem); setWhichPdfEdit("studentDistress") }}
                                  type="button" data-toggle="modal" data-target="#exampleModal7" /> : ""
                              }

                            </p>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                <div className="risk-statement d-flex">
                  <b
                    className="text-uppercase highlighted-text w-100 d-flex justify-content-between "
                    style={{ marginBottom: "10px" }}
                  >
                    BENEVOLENT FUND SCHEME
                    {
                      userExists ?
                        <FaPlusCircle style={{ fontSize: "18px", color: "white" }} onClick={() => { setTitle("CONTACT PERSON:"); setAddEditToggle("Add Benevolent fund scheme"); setConditionIs("Add_Benevolent_fund_scheme") }} color="#c21717" type="button" data-toggle="modal" data-target="#exampleModal6" /> : ""
                    }
                  </b>

                  <br />
                  {/* <b className="lead mr-lg-3 mr-2 text-uppercase mt-2">
                  The Policy covers the students for the following Risks:
                </b> */}
                </div>
                <ul>
                  <li>
                    <ul>
                      {
                        benevolentfundscheme?.map((elem) => {
                          return (
                            <>
                              <div className="d-flex align-items-center">

                                <li className="custom-list-item d-flex " style={{ width: "95%" }}>
                                  {elem.content}
                                </li>
                                {
                                  userExists ? <>
                                    <FaEdit className="mr-2 ml-1 text-primary" onClick={() => handleCurrentEdit(elem, "benevolent_fund_scheme")} data-toggle="modal" data-target="#exampleModal6" /> <FaTrash onClick={() => { deleteBenevolent(elem.id) }} className="mr-2 ml-1 text-danger" />
                                  </> : ""
                                }
                              </div>
                            </>
                          )
                        })
                      }
                      {" "}
                      <br />
                      {BENEVOLENTFUNDSCHEMEPDF?.map((elem) => {
                        // console.log(BENEVOLENTFUNDSCHEMEPDF)
                        return (
                          <>
                            <p className="mb-1">
                              <span
                                className="custom-list-item moreinformation "
                                style={{ color: "#4d4d4e", fontSize: "14px" }}
                              >
                                {elem.title}
                              </span>
                              <a
                                className="ms-1"
                                href={`${Base_Url}/${elem?.pdf_link
                                  ?.replace(/\\/g, "/")
                                  .replace("uploads/", "")}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="pdf-icon " style={{ fontSize: "14px" }}>
                                  {elem.pdf_title}
                                </span>
                                <FaFilePdf />
                              </a>
                              {
                                userExists ? <FaEdit className="mr-2 ml-1 text-warning"
                                  onClick={() => { pdfEdit(elem); setWhichPdfEdit("benevolent") }}
                                  type="button" data-toggle="modal" data-target="#exampleModal7" /> : ""
                              }

                            </p>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>


          </div>
        </div>
      </div>
    </>
  );
};

export default YourComponent;

