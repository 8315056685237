import React from 'react';
import './sidedetails.css';

const SideDetails = () => {
  return (
    <div className="side-details-container">
      <div className="bad">
        <div className="wpb_wrapper">
          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">
              <h5><strong>National Anti-Ragging Helpline</strong></h5>
              <h6>
                <span className="highlight"><strong>24×7 Toll Free</strong></span><br />
                <strong>1800-180-5522</strong><br />
                <a  target='_blank' style={{textTransform:"lowercase"}} href="mailto:helpline@antiragging.in">helpline@antiragging.in</a> | <a  target='_blank' style={{textTransform:"lowercase"}} href="http://www.antiragging.in">www.antiragging.in</a>
              </h6>
            </div>
          </div>
          <hr />
          
         
          
          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">
              <h6 className="section-title"><strong>UGC Monitoring Agency</strong></h6>
              <p>
                Centre for Youth (C4Y)<br />
                <a target='_blank' href="mailto:antiragging@c4yindia.org">antiragging@c4yindia.org</a> | <a  target='_blank' href="http://www.c4yindia.org">www.c4yindia.org</a>
              </p>
              <strong>0981-804-4577</strong><br />
            </div>
          </div>
          <hr />
          
         
          
          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">
              <h6 className="section-title">
                <strong>Contact Details of the Nodal Officers of Anti-Ragging Committee and Squad</strong>
              </h6>
              <p>
                <u>Anti-Ragging Committee (ARC)</u> | <u>Anti-Ragging Squad (ARS)</u>
              </p>
            </div>
          </div>
          
         
          <hr />
          
          <div className="wpb_text_column wpb_content_element">
            <div className="wpb_wrapper">
              <h6 className="section-title">
                <strong>RAGGING IS A CRIMINAL OFFENCE AND THE CULPRITS WILL ATTRACT PUNITIVE ACTION AS MENTIONED IN THE UGC REGULATIONS</strong>
                <p style={{textTransform:"lowercase"}}>
                (<a  target='_blank' href="http://www.antiragging.in/assets/pdf/annexure/Annexure-I.pdf">www.antiragging.in/assets/pdf/annexure/Annexure-I.pdf</a>)
                </p>
              </h6>
              <p>
              <a target='_blank' href="http://www.ugc.ac.in">www.ugc.ac.in</a>
                {/* <strong>www.ugc.ac.in</strong> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideDetails;
