import React, { useEffect, useState } from "react";
// import img1 from "../Assests/iitddoslogo.png";
import img1 from "../Assests/footerlogonew.png";
import {
  FaBars,
  FaFacebookF,
  FaLinkedinIn,
  FaTimes,
  FaTwitter,
  FaUserEdit,
} from "react-icons/fa";
import { ImArrowUpRight } from "react-icons/im";
import "./responsive-style.css";
import "./style.css";
import { FaHome } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { resetUser, setUserData } from "../Redux/userSlice";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { LuLogOut } from "react-icons/lu";
import { RiShutDownLine } from "react-icons/ri";
import { Base_Url} from "./config";

const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the component mounts
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(windowWidth);

  const [state, setState] = useState(" ");
  const navigateTo = useNavigate();
  useEffect(() => {
    // console.log("add state");
    window.addEventListener("scroll", headerTop);
    return () => {
        // console.log("remove state");
      window.removeEventListener("scroll", headerTop);
    };
  });
  const headerTop = () => {
    // console.log("ok" + window.scrollY);
    if (window.scrollY >= 120) {
      setState({ x: " header-scrolled " });
    } else {
      setState({ x: "" });
    }
  };

  const dispatch = useDispatch();
  const userExists = useSelector((state) => state.user.userExists);
  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log(userDataRedux,"userData")
  // console.log(userExists,"userExists")

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 250);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const accessAction1 = localStorage.getItem("login");
  const accessAction2 = localStorage.getItem("userId");

  const logoutFunc = () => {
    localStorage.clear();
    toast.success("Logout successfully!");
    navigateTo("/");

      setUserDataFromOauth();
      setUserExistsState(false);
     // Accessing the last value of secondData array
      dispatch(
        setUserData({
          userData: null,
          userExists: false,
        })
      );
       dispatch(resetUser())
  };

  
  const [userDatafromOauth, setUserDataFromOauth] = useState(null);
  const [userExistsState, setUserExistsState] = useState(false);


  // console.log(userExistsState)
  // console.log(userDatafromOauth)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Base_Url}/admin-user-data`, {
          method: "GET",
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data,"currrent users");

        if (data) {
          const secondResponse = await fetch(
            `${Base_Url}/admin-userExists/${data.user_id}`,
            {
              method: "GET",
            }
          );

          const secondData = await secondResponse.json();
          // console.log( secondData[secondData.length - 1]?.user_id );
          // console.log( secondData,"redux data" );

          // Adjust property name in the filter based on the actual structure of secondData
          // const filterUserIfExists = secondData.filter(
          //   (elem) => elem.user_id == data.user_id
          // );
          // console.log(filterUserIfExists);


          
          // Set state based on the filter result
          // if (filterUserIfExists.length > 0) {
          //   setUserDataFromOauth(data);
          //   setUserExistsState(true);
          //   // dispatch(setUserData(data));
          //   dispatch(
          //     setUserData({
          //       // userData: data, // You might want to update this with actual user data
          //       userData: secondData, // You might want to update this with actual user data
          //       userExists: true,
          //     })
          //   );
          // }

          // console.log(secondData)
          if (typeof secondData === 'object' && secondData !== null) {
           
            setUserDataFromOauth(data);
            setUserExistsState(true);
            // const lastUserData = secondData[secondData.length - 1]?.user_id; // Accessing the last value of secondData array
            dispatch(
              setUserData({
                // userData: lastUserData,
                userData: secondData,
                userExists: true,
              })
            );
          }
          
          
        }
        // console.log(data.user_id);
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  // console.log(userDatafromOauth); // This will log the initial state, which is null

  // console.log(userExistsState);

  const [isAllowed, setIsAllowed] = useState(true);

  const [localIP, setLocalIP] = useState(null);

  useEffect(() => {
    const fetchLocalIP = async () => {
      try {
        const response = await fetch(`${Base_Url}/admin-getLocalIP`);
        const data = await response.json();
        // console.log(data);

        // Check if the local IP starts with "10."
        const allowedIPPrefix = "10.";
        const isAllowed = data.localIP.startsWith(allowedIPPrefix);

        setLocalIP(data.localIP);
        setIsAllowed(isAllowed);
      } catch (error) {
        // console.error("Error fetching local IP:", error);
      }
    };

    fetchLocalIP();
  }, []);

  // console.log(isAllowed)
  // console.log(localIP)

  // Client-side code (React)

  const logoutUser = async () => {
    try {
      const response = await fetch(`${Base_Url}/admin-logout`, {
        method: "GET",
        credentials: "include", // Include credentials for sending cookies
      });

      // console.log(response);
      if (response.ok) {
        // Redirect or perform any other actions after successful logout
        navigateTo("/")
        window.location.reload();
      } else {
        // console.error("Logout failed");
        window.location.reload();
      }
    } catch (error) {
      // console.error("Error during logout:", error);
      window.location.reload();
    }
  };
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetch(`${Base_Url}/admin-auth/user-data`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          setIsAuthenticated(true);

          // Update Redux state using the setUserData action
          dispatch(
            setUserData({
              userData: null, // You might want to update this with actual user data
              // userExists: true,
            })
          );
        } else {
          setIsAuthenticated(false);

          // Update Redux state using the setUserData action
          dispatch(
            setUserData({
              userData: null,
              userExists: false,
            })
          );
        }
      } catch (error) {
        // console.error("Error checking authentication:", error);
        setIsAuthenticated(false);

        // Update Redux state using the setUserData action
        dispatch(
          setUserData({
            userData: null,
            userExists: false,
          })
        );
      }
    };

    checkAuthentication();
  }, [dispatch]);

  // console.log(isAuthenticated);

  // const logoutUser = ()=>{
  //   // Function to delete a cookie by name
  //   document.cookie.split(";").forEach((cookie) => {
  //     const [name] = cookie.split("=");
  //     document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  //   });

  // }

  // console.log(document.cookie);

  function getAllCookies() {
    const cookies = document.cookie.split(";").reduce((acc, cookie) => {
      const [name, value] = cookie.trim().split("=");
      acc[decodeURIComponent(name)] = decodeURIComponent(value);
      return acc;
    }, {});

    return cookies;
  }

  // Example usage
  const allCookies = getAllCookies();
  // console.log(allCookies);

  return (
    <>
      <header className="header_wrapper" id="top">
        <div
          className={`topbar bg-theme-colored2${
            isScrolled ? " header-scrolled" : ""
          }`}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 xs-mb-10 text-center text-lg-start text-md-start ">
                <div className="topbar-call">
                  <ul
                    className="mb-0 upperul"
                    style={{ listStyle: "none", padding: 0, fontSize: "13px" }}
                  >
                    <li className="text-lg-start  text-center d-flex justify-content-lg-start justify-content-center justify-content-sm-center justify-content-md-center align-content-md-center align-content-sm-center">
                      {/* <FaHome className="icons iupperheader" style={{ color: "white" }} /> */}
                      <a
                        className="icons iupperheader"
                        href="https://home.iitd.ac.in/"
                        target="_blank"
                        style={{
                          color: "white",
                          marginLeft: "8px",
                          transition: "color 0.3s ease",
                        }}
                      >
                        <span>
                        Go to IITD Website 
                        </span>
                        <span>
                        <ImArrowUpRight className="icons ml-2" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex justify-content-lg-end mt-sm-2 mt-lg-0   justify-content-center">
                <div className="topbar-social text-lg-start text-center text-md-end">
                  <ul
                    className="mb-0"
                    style={{
                      listStyle: "none",
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <li>
                      <a
                        href="https://twitter.com/iitdelhi"
                        style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                      >
                        <FaTwitter
                          className="icons iupperheader"
                          style={{ color: "white" }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/school/iitdelhi/"
                        style={{
                          color: "white",
                          marginLeft: "15px",
                          transition: "color 0.3s ease",
                        }}
                      >
                        <FaLinkedinIn
                          className="icons iupperheader"
                          style={{ color: "white" }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/IITDelhi/"
                        style={{
                          color: "white",
                          marginLeft: "15px",
                          transition: "color 0.3s ease",
                        }}
                      >
                        <FaFacebookF
                          className="icons iupperheader"
                          style={{ color: "white" }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          className={`navbar p-lg-0 ps-2 pt-2 pe-2 pb-0 mediaNavbar mb-2 mb-lg-0 navbar-expand-lg navbar-light bg-light${
            isScrolled ? " header-scrolled" : ""
          }`}
        >
          <div className="container navbariitd p-0">
            <Link
              to="/"
              className="navbar-brand iitda me-0"
              style={{ width: "30%" }}
            >
              <img
                className="w-lg-100 w-100 iitdlogo h-100 w-sm-50 iitd-logo mt-0"
                // src="https://bioschool.iitd.ac.in/public/frontend/images/logo.png"
                src={img1}
                alt="img..."
                width="30"
                height="24"
              />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              onClick={handleToggle}
            >
              {isOpen ? <FaTimes /> : <FaBars />}
            </button>
            <div
              className={` position-relative collapse navbar-collapse justify-content-end${
                isOpen ? " show" : ""
              }`}
            >
              <ul
                className=" position-absolute navbar-nav menu-navbar-nav justify-content-start align-items-center start-0"
                style={{
                  left: isAllowed ? "50px" : "200px",
                  paddingLeft: "10px",
                }}
              >
                <li className="nav-item w-100">
                  <Link
                    to="/"
                    className="nav-link p-lg-0 p-1 d-flex justify-content-start "
                    style={{ fontSize: "14px" }}
                    onClick={handleToggle}
                  >
                    <FaHome style={{ color: "#C21717" }} />
                  </Link>
                </li>
                <li className="nav-item w-100">
                  <NavLink
                    to="/about"
                    className="nav-link p-lg-0 p-1 d-flex justify-content-start"
                    style={{ fontSize: "14px" }}
                    activeClassName="active"
                    onClick={handleToggle}
                  >
                    ABOUT
                  </NavLink>
                </li>
                <li className="nav-item w-100">
                  <NavLink
                    to="/board"
                    className="nav-link p-lg-0 p-1 d-flex justify-content-start"
                    activeClassName="active"
                    style={{ fontSize: "14px" }}
                    onClick={handleToggle}
                  >
                    BOARDS @ IIT DELHI
                  </NavLink>
                </li>
                <li className="nav-item w-100">
                  <NavLink
                    to="/antiragging"
                    className="nav-link p-lg-0 p-1 d-flex justify-content-start"
                    activeClassName="active"
                    style={{ fontSize: "14px" }}
                    onClick={handleToggle}
                  >
                    ANTI-RAGGING @ IIT DELHI
                  </NavLink>
                </li>
                <li className="nav-item w-100">
                  <NavLink
                    to="/funds"
                    className="nav-link p-lg-0 p-1 d-flex justify-content-start"
                    activeClassName="active"
                    style={{ fontSize: "14px" }}
                    onClick={handleToggle}
                  >
                    FUNDS & MEDICAL INSURANCE
                  </NavLink>
                </li>
                {userExistsState ? (
                  <>
                    {windowWidth <= 768 ? (
                    <>
                      <li className="nav-item w-100">
                        <Link
                          to="/"
                          className="nav-link p-lg-0 p-1 d-flex justify-content-between "
                          style={{ fontSize: "14px" }}
                          onClick={handleToggle}
                          >
                          <span style={{ color: "#C21717" }}>
                            {" "}
                            {userDatafromOauth?.name}{" "} 
                          </span>{" "}
                          <span onClick={logoutUser}>
                            <RiShutDownLine
                              style={{ color: "#C21717", fontSize: "18px" }}
                              />
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item w-100">
                        <Link
                          to="/manage-users"
                          className="nav-link p-lg-0 p-1 d-flex justify-content-between "
                          style={{ fontSize: "14px" }}
                          onClick={handleToggle}
                        >
                          <span style={{ color: "#C21717" }}>
                            {" "}
                            MANAGE USERS
                          </span>{" "}
                          <span  >
                          <FaUserEdit style={{ color: "#C21717", fontSize: "18px" }}/>
                          </span>
                        </Link>
                      </li>
                    </>
                    ) : (
                      <>
                        <div class="dropdown show">
                          <a
                            className=" dropdown-toggle nav-link text-uppercase logout  align-items-center p-lg-0 p-1 d-flex justify-content-start"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{
                              fontWeight: windowWidth >= 1440 ? "700" : "700",
                              marginLeft: windowWidth <= 1024 ? "0" : "10px",
                            }}
                          >
                            {userDatafromOauth?.name}
                          </a>

                          <div
                            class="dropdown-menu p-0"
                            aria-labelledby="dropdownMenuLink"
                            style={{
                              width: "125px",
                              left: "-75px",
                              top: "25px",
                            }}
                          >
                            <div
                              class="dropdown-item align-items-center d-flex justify-content-between p-2 align-items-center"
                              href=""
                              style={{ color: "#C21717" }}
                              onClick={logoutUser}
                            >
                              <span
                                style={{ fontSize: "12px", fontWeight: "700" }}
                              >
                                LOGOUT
                              </span>{" "}
                              <span className="d-flex justify-content-between align-items-center">
                                <LuLogOut />
                              </span>
                            </div>
                            <div
                              class="dropdown-item align-items-center d-flex justify-content-between p-2 align-items-center"
                              href=""
                              style={{ color: "#C21717" }}
                            >
                              <Link to="/manage-users"
                                style={{ fontSize: "12px", fontWeight: "700" ,color:"#c21717" }}
                              >
                                MANAGE USERS
                              </Link>{" "}
                              <span className="d-flex justify-content-between align-items-center">
                                <FaUserEdit />
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* <Link  ></Link>  */}
                  </>
                ) : (
                  isAllowed && (
                    <li className="nav-item w-100">
                      {accessAction1 && accessAction2 ? (
                        <Link
                          to="/login"
                          onClick={logoutFunc}
                          style={{ color: "#C21717" }}
                          className="nav-link logout p-lg-0 p-1 d-flex justify-content-start"
                        >
                          LOGOUT
                        </Link>
                      ) : (
                        <Link
                          to="/login"
                          style={{
                            color: "#C21717",
                            fontSize: "14px",
                            paddingTop: "0px",
                            fontWeight: "700",
                          }}
                          className=" logout p-lg-0  d-flex justify-content-start"
                        >
                          
                        </Link>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <ToastContainer />
    </>
  );
};

export default Navbar;