import React, { useEffect, useRef, useState } from "react";
import "./Board.scss";
import brca from "../Assests/brca.afcd810443d455d0dff1.jpg"
import bhm from "../Assests/logo.d89826e86ae29b706edc.jpg"
import bsw from "../Assests/bsw.f557ef0f939d2ea714b3.jpg"
import bsa from "../Assests/bsa.5f5664b30979e818566c.jpg"
import bsp from "../Assests/bsp.png"
import axios from 'axios';
import { FaCloudUploadAlt, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Base_Url} from "./config";


const Board = () => {

  const [boards, setBoards] = useState([]);
  const userExists = useSelector((state) => state.user.userExists);
  const [edit, setEdit] = useState(userExists);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileName, setFileName] = useState(null);

  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log(userDataRedux,"userData")

  const fetchBoards = async () => {
    try {
      const response = await axios.get(`${Base_Url}/get-boards`); // Replace this URL with your API endpoint
      // console.log(response.data)
      setBoards(response.data.map(boards => {
        // If image_data is available, convert it to a data URL
        if (boards.board_img) {
          return {
            ...boards,
            board_img: `${Base_Url}/${boards.board_img}` // Adjust image URL here
          };
        }
        return boards;
      }));
    } catch (error) {
      // console.error('Error fetching boards:', error);
    }
  };
  useEffect(() => {
    fetchBoards();
  }, []);


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedBoard({
      ...selectedBoard,
      board_img: file // Update selectedBoard with the uploaded image file
    });
    // Set previewImage with the created object URL after removing "uploads\\" from the image URL
    setPreviewImage(URL?.createObjectURL(file).replace(/\\/g, '/').replace('uploads/', ''));
  };

  // const handlePdfUpload = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedBoard({
  //     ...selectedBoard,
  //     board_constitution_pdf: file // Update selectedBoard with the uploaded image file
  //   });
  //   // Set previewImage with the created object URL after removing "uploads\\" from the image URL
  //   // setPreviewImage(URL.createObjectURL(file).replace(/\\/g, '/').replace('uploads/', ''));
  // };

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    setFileName(file)
    setSelectedBoard(prevSelectedBoard => ({
      ...prevSelectedBoard,
      board_constitution_pdf: file
    }));
  };


  const getShortForm = (fullName) => {
    // Define an array of words to exclude from short form
    const excludeWords = ["for", "and"];

    // Split the full name into an array of words
    const words = fullName.split(' ');

    // Map over the array to extract the first letter of each word and capitalize it
    // Exclude certain words from short form
    const shortForm = words
      .filter(word => !excludeWords.includes(word.toLowerCase())) // Exclude specified words
      .map(word => word.charAt(0).toUpperCase())
      .join('');

    return shortForm;
  };


  // const handleDelete = async (id) => {
  //   const confirmDelete = window.confirm("Are you sure you want to delete this member?");
  //   if (confirmDelete) {
  //     try {
  //       await axios.put(`${Base_Url}/boards-soft-delete/${id}`, { is_delete: true });
  //       // Filter out the deleted board from the UI state
  //       setBoards(prevBoards => prevBoards.filter(board => board.id !== id));
  //       fetchBoards();
  //     } catch (error) {
        // console.error('Error deleting board:', error);
  //     }
  //   }
  // };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this member?");
    if (confirmDelete) {
      try {
        const deleted_by = userDataRedux; // Assuming you have access to the user's ID
        await axios.put(`${Base_Url}/boards-soft-delete/${id}`, { deleted_by: deleted_by?.user_id });
        
        // Filter out the deleted board from the UI state
        setBoards(prevBoards => prevBoards.filter(board => board.id !== id));
        
        fetchBoards();
      } catch (error) {
        // console.error('Error deleting board:', error);
      }
    }
  };
  
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  };

  const handleEdit = (board) => {
    setSelectedBoard({
      ...board,
    });

    // Ensure board_img is not null before setting previewImage
    if (board.board_img) {
      // Set previewImage with the board_img URL after removing "uploads\\" from the image URL
      setPreviewImage(board.board_img.replace(/\\/g, '/').replace('uploads/', ''));
    }
  };


  // console.log(selectedBoard)

  const handleUpdateSave = async () => {
    try {
      const formData = new FormData();
      formData.append('image', selectedBoard.board_img);
      formData.append('board_name', selectedBoard.board_name);
      formData.append('board_url', selectedBoard.board_main_website_url);
      formData.append('board_description', selectedBoard.board_description);
      formData.append('board_constitution_pdf', selectedBoard.board_constitution_pdf);
      formData.append('board_main_website_url', selectedBoard.board_main_website_url);
      formData.append('updated_by',  userDataRedux?.user_id);

      const currentDate = new Date().toISOString();
      formData.append('updated_on', currentDate);

      await axios.put(`${Base_Url}/update-boards/${selectedBoard.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Data Updated successfully');
      fetchBoards();
      closeButtonRef.current.click();
    } catch (error) {
      // console.error('Error saving changes:', error);
    }
  };

  // console.log(previewImage)

  // Inside the modal content

  // Function to handle form submission

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData3({
      ...formData3,
      [name]: value,
    });
  };



  const [formData3, setFormData3] = useState({
    board_name: '',
    board_description: '',
    board_main_website_url: '',
    image: null,
    board_constitution_pdf: null
  });

  // console.log(formData3)
  const handleSubmit = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('board_name', formData3.board_name);
      formDataToSend.append('board_description', formData3.board_description);
      formDataToSend.append('board_main_website_url', formData3.board_main_website_url);
      formDataToSend.append('image', formData3.image);
      formDataToSend.append('board_constitution_pdf', formData3.board_constitution_pdf);
      formDataToSend.append('created_by',  userDataRedux?.user_id);

      const response = await fetch(`${Base_Url}/create-boards`, {
        method: 'POST',
        body: formDataToSend
      });

      if (response.ok) {
        // Handle success
        toast.success('Board added successfully');
        closeButtonRef2.current.click();
        fetchBoards()
      } else {
        // Handle error
        // console.error('Error adding new board:', response.statusText);
      }
    } catch (error) {
      // console.error('Error adding new board:', error.message);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData3({ ...formData3, [name]: files[0] });
  };


  const closeButtonRef = useRef(null);
  const closeButtonRef2 = useRef(null);


  return (
    <div>
 <div className="modal fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
            <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Add Boards</h5>
            <button className="text-white close" type="button" ref={closeButtonRef2}  data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "20px" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="name">Board Name :</label>
                          <input
                            type="text"
                            className="p-2 form-control mt-2"
                            id="name"
                            name="board_name"
                            placeholder="Enter name"
                            value={formData3.board_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="role">Board Description :</label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="board_description"
                            value={formData3.board_description}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="moreInfoLink">Board Main Website :  </label>
                          <input
                            type="text"
                            className="p-2 form-control mt-2"
                            id="moreInfoLink"
                            name="board_main_website_url"
                            placeholder="Enter more info link"
                            value={formData3.board_main_website_url}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="image">Board Image:</label>
                          <input
                            type="file"
                            name="image"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            className="form-control-file mt-2"
                            id="imageInput" // Unique ID
                          />
                          <button type="button" className="btn btn-secondary mt-2" onClick={() => document.getElementById('imageInput').click()}>
                            {formData3?.image ? formData3?.image?.name : "Upload IMG"}
                          </button>
                        </div>
                        <div className="form-group">
                          <label className="mb-0" htmlFor="image">Board Constitution:</label>
                          <input
                            type="file"
                            name="board_constitution_pdf"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            className="form-control-file mt-2"
                            id="constitutionInput" // Unique ID
                          />
                          <button type="button" className="btn btn-secondary mt-2" onClick={() => document.getElementById('constitutionInput').click()}>
                            {formData3?.board_constitution_pdf?.name ? formData3?.board_constitution_pdf?.name : "Upload PDF"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button type="button" className="btn btn-success mt-4 btn-block w-25" onClick={handleSubmit}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




      <section className="light">
        <div className="container py-2">
          <div className="h1 mb-5 text-center text-dark">
            <span style={{ color: "#c21717" }}>BOARDS</span> <span style={{ color: "#c21717" }}>@</span> <span>IIT DELHI</span>
          </div>


          {
            boards?.map((board) => {
              if (board.is_delete) {
                return null; // Skip rendering soft-deleted members
              }

              const imageURL = board?.board_img ? board?.board_img.replace(/\\/g, '/').replace('uploads/', '') : null;

              // console.log(imageURL)
              return (
                <>
                  <article key={board.id} className="postcard light red">
                    <a className="postcard__img_link" href="#">
                      {/* <img className="postcard__img" src={board.board_img} alt="Image Title" /> */}
                      <img className="postcard__img" src={imageURL} alt="Image Title" />
                    </a>
                    <div className="postcard__text t-dark">
                      <h1 className="postcard__title red" >
                        <a href={board?.board_url} className="postcard__title red_a" target="_blank" rel="noopener noreferrer">{board?.board_name}</a>
                      </h1>
                      <div className="postcard__subtitle small">
                        <time dateTime="2020-05-25 12:00:00">
                          <i className="fas fa-calendar-alt mr-2"></i>{formatDate(board?.updated_on)}
                        </time>
                      </div>
                      <div className="postcard__bar"></div>
                      <div className="postcard__preview-txt">
                        {board?.board_description}
                      </div>
                      <ul className="postcard__tagbox">
                        <li className="tag__item play red whiteHover">
                          <a href={`${Base_Url}/${board?.board_constitution_pdf.replace(/\\/g, '/').replace('uploads/', '')}`} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-play mr-2"></i> {getShortForm(board.board_name)} Constitution
                          </a>
                        </li>
                        <li className="tag__item play red whiteHover mainWeb">
                          <a href={board?.board_main_website_url} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-play mr-2"></i>Main Website
                          </a>
                        </li>
                      </ul>
                    </div>
                    {
                      userExists ? <div className="p-2" style={{ zIndex: "1" }}>
                        <div style={{ fontSize: "20px", zIndex: 100 }}> {/* Increase size to 24px */}
                          <FaEdit color="black" type="button" onClick={() => handleEdit(board)} data-toggle="modal" data-target="#exampleModal" />
                        </div>
                        <div onClick={() => handleDelete(board.id)} style={{ fontSize: "20px" }}> {/* Increase size to 24px */}
                          <FaTrash color="#c21717" />
                        </div>
                      </div> : ""
                    }

                  </article>
                </>
              )
            })
          }
        </div>
      </section>
      {
        userExists ?  <div className="d-flex justify-content-center align-items-center ">
        <div >
          <button type="button" data-toggle="modal" data-target="#exampleModal3" className="btn btn-success mb-5 btn-sm mt-3 p-2"
          //  onClick={() => saveChanges(selectedMemberIndex)}
          >
            Add Boards
          </button>
        </div>

      </div> :""
      }
     
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
              <h5 className="modal-title text-center" id="exampleModalLabel">Edit Boards</h5>
              <button className="text-white close" type="button" ref={closeButtonRef} data-dismiss="modal" aria-label="Close" style={{ marginTop: "-10px" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {selectedBoard && (
              <div className="modal-body">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-3 align-items-center">
                                <div className="mb-3">
                                  {previewImage && selectedBoard && <img src={previewImage} className="img-fluid" alt="Board Preview" />}
                                  <label htmlFor="file-upload" className="custom-file-upload">
                                    <FaCloudUploadAlt /> Upload Image
                                  </label>
                                  <input id="file-upload" type="file" onChange={handleImageUpload} style={{ display: "none" }} />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <input type="text" className="form-control" value={selectedBoard.board_name} onChange={(e) => setSelectedBoard({ ...selectedBoard, board_name: e.target.value })} />
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <textarea className="form-control" value={selectedBoard.board_description} onChange={(e) => setSelectedBoard({ ...selectedBoard, board_description: e.target.value })} rows="5"></textarea>
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex justify-content-center align-items-center">
                                    <label htmlFor="pdf-upload" className="custom-file-upload">
                                      <FaCloudUploadAlt />  {typeof selectedBoard?.board_constitution_pdf === 'string' ? selectedBoard.board_constitution_pdf.split(/[\\/]/).pop() : fileName?.name}
                                    </label>
                                    <input id="pdf-upload" type="file" onChange={handlePdfUpload} style={{ display: "none" }} />
                                  </div>
                                  <div className="col-md-6 mb-3 d-flex justify-content-center align-items-center">
                                    <input type="text" className="form-control" value={selectedBoard.board_main_website_url} onChange={(e) => setSelectedBoard({ ...selectedBoard, board_main_website_url: e.target.value })} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer text-right">
                          <button className="btn" style={{ background: "#c21717", color: "white" }} onClick={handleUpdateSave}>Save Changes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>


    </div>


  );
};

export default Board;
