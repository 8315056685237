// import React, { useEffect, useState } from 'react';
// import Modal from 'react-modal';
// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { PiFilePdfDuotone } from "react-icons/pi";
// import { FaEdit, FaPlus, FaTrash, FaUpload, FaTimes } from 'react-icons/fa';
// import "./PdfAntiragging.css";
// import { Base_Url } from './config';
// import { MdClose } from "react-icons/md";
// import { Document, Page,pdfjs } from 'react-pdf';
// import axios from 'axios';
// import { useSelector } from 'react-redux';

// Modal.setAppElement('#root');

// function Pdf() {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [editModalIsOpen, setEditModalIsOpen] = useState(false);
//   const [addModalIsOpen, setAddModalIsOpen] = useState(false);
//   const [pdfFile, setPdfFile] = useState('');
//   const [selectedPdf, setSelectedPdf] = useState({});
//   const [pdfPath, setPdfPath] = useState('');
//   const [advisory, setAdvisory] = useState([]);
//   const [newImage, setNewImage] = useState(null);
//   const [editImagePreview, setEditImagePreview] = useState(null);
//   const [newPdf, setNewPdf] = useState(null);
//   const [editImage, setEditImage] = useState(null);
//   const [editPdf, setEditPdf] = useState(null);
//   const [newImageName, setNewImageName] = useState('');
//   const [newPdfName, setNewPdfName] = useState('');
//   const [editImageName, setEditImageName] = useState('');
//   const [editPdfName, setEditPdfName] = useState('');

//   const userExists = useSelector((state) => state.user.userExists);
//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
//   const openModal = (pdfPath) => {
//     setPdfFile(pdfPath);
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//     setPdfFile('');
//   };

//   const openEditModal = (e, pdf) => {
//     e.stopPropagation();
//     setSelectedPdf(pdf);
//     setPdfPath(pdf.path);
//     setEditImage(null);
//     setEditPdf(null);
//     setEditImagePreview(pdf.src); // Set existing image as preview on modal open
//     setEditImageName(''); // Reset edit image name
//     setEditPdfName(''); // Reset edit PDF name
//     setEditModalIsOpen(true);
//   };

//   const closeEditModal = () => {
//     setEditModalIsOpen(false);
//     setSelectedPdf({});
//   };

//   const handleEditImageChange = (e) => {
//     const file = e.target.files[0];
//     setEditImage(file); // Save the file object in state
//     setEditImagePreview(URL.createObjectURL(file)); // Generate a preview URL
//     setEditImageName(file.name); // Set the edit image name
//   };

//   const handleEditPdfChange = (e) => {
//     const file = e.target.files[0];
//     setEditPdf(file); // Save the file object in state
//     setEditPdfName(file.name); // Set the edit PDF name
//   };

//   const handleSaveChanges = () => {
//     const formData = new FormData();
//     if (editImage) formData.append('image', editImage);
//     if (editPdf) formData.append('pdf', editPdf);

//     axios.put(`${Base_Url}/update-anticommitte/${selectedPdf.id}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then(response => {
//       closeEditModal();
//       fetchAdvisory();
//     })
//     .catch(error => {
//       console.error('Error updating advisory:', error);
//     });
//   };

//   const openAddModal = () => {
//     setAddModalIsOpen(true);
//   };

//   const closeAddModal = () => {
//     setAddModalIsOpen(false);
//     setNewImage(null);
//     setNewPdf(null);
//     setNewImageName(''); // Reset new image name
//     setNewPdfName(''); // Reset new PDF name
//   };

//   const handleNewImageChange = (e) => {
//     const file = e.target.files[0];
//     setNewImage(file);
//     setNewImageName(file.name); // Set the new image name
//   };

//   const handleNewPdfChange = (e) => {
//     const file = e.target.files[0];
//     setNewPdf(file);
//     setNewPdfName(file.name); // Set the new PDF name
//   };

//   const handleAddNew = () => {
//     const formData = new FormData();
//     if (newImage) formData.append('image', newImage);
//     if (newPdf) formData.append('pdf', newPdf);

//     fetch(`${Base_Url}/create-anticommitte`, {
//       method: 'POST',
//       body: formData
//     })
//       .then(response => {
//         if (response.ok) {
//           closeAddModal();
//           setNewImage(null);
//           setNewPdf(null);
//           fetchAdvisory()
//         } else {
//           console.error('Failed to upload PDF and image');
//         }
//       })
//       .catch(error => {
//         console.error('Error uploading PDF and image:', error);
//       });
//   };

//   const fetchAdvisory = async () => {
//     try {
//       const response = await axios.get(`${Base_Url}/anticommittes`);
//       setAdvisory(response.data)
//     } catch (error) {
//       console.error('Error fetching advisories:', error);
//     }
//   };

//   useEffect(() => {
//     fetchAdvisory();
//   }, []);

//   const handleDeleteAdvisory = async (id) => {
//     const confirmed = window.confirm('Are you sure you want to delete this advisory?');
//     if (confirmed) {
//       try {
//         await axios.delete(`${Base_Url}/anticommitte/${id}`);
//         fetchAdvisory();
//       } catch (error) {
//         console.error('Error deleting advisory:', error);
//       }
//     }
//   };

//   const defaultLayoutPluginInstance = defaultLayoutPlugin();

//   return (
//     <div className="pdf mt-5">
//       <div className="h1 headingSpan mb-4 text-center text-dark">
//       <hr />
//         <span style={{ color: "#c21717" }}>ANTI-RAGGING</span>{" "}
//         <span style={{ color: "#c21717" }}>@</span> <span>Committee</span>
//       <hr />
//         {
//           userExists ?  <span>
//           <FaPlus color='#c21717' className='ms-2' onClick={openAddModal} style={{ cursor: 'pointer' }} />
//         </span> :""
//         }

//         <p></p>
//       </div>

//       <div className="inception-images mb-5" style={{ height: "650px", cursor: "pointer" }}>
//         {
//           advisory && advisory.map((elem) => (
//             <div key={elem.id} className="image-container" onClick={() => openModal(`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`)}>
//               <img className='img' style={{ cursor: "pointer" }} src={`${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`} alt="Say no To Ragging..." />
//               <div className="icon-overlay" style={{ cursor: "pointer" }}>
//                 <PiFilePdfDuotone />
//               </div>
//               {
//                 userExists ? <div className="action-buttons">
//                 <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id, src: `${Base_Url}/${elem.image_path.replace(/\\/g, '/').replace('uploads/', '')}`, path: `${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}` })}><FaEdit /></button>
//                 <button className="delete-button" onClick={(e) => {e.stopPropagation(); handleDeleteAdvisory(elem.id)}}><FaTrash /></button>
//               </div> :""
//               }

//             </div>
//           ))
//         }
//       </div>

//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="PDF Modal"
//         className="pdf-modal dark-mode"
//         overlayClassName="pdf-modal-overlay dark-overlay"
//       >
//         <button onClick={closeModal} className="close-button dark-mode">Close</button>
//         {pdfFile && (
//           <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
//             <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
//           </Worker>
//         )}
//       </Modal>

//       <Modal
//         isOpen={addModalIsOpen}
//         onRequestClose={closeAddModal}
//         shouldCloseOnOverlayClick={true}
//         shouldCloseOnEsc={true}
//         contentLabel="Add PDF Modal"
//         className="add-modal-container"
//         overlayClassName="add-modal-overlay"
//       >
//         <div className="add-modal">
//           <div className="add-modal-header">
//             <h2>Add New PDF</h2>
//             <button onClick={closeAddModal} className="close-button">
//               <MdClose />
//             </button>
//           </div>
//           <div className="add-modal-body">
//             <div className="file-input">
//               <label htmlFor="image-upload" className="file-input-label">
//                 <FaUpload className="upload-icon" />
//                 {newImageName ? newImageName : "Upload Image"}
//               </label>
//               <input
//                 id="image-upload"
//                 type="file"
//                 accept="image/*"
//                 onChange={handleNewImageChange}
//               />
//             </div>
//             <div className="file-input">
//               <label htmlFor="pdf-upload" className="file-input-label">
//                 <FaUpload className="upload-icon" />
//                 {newPdfName ? newPdfName : "Upload PDF"}
//               </label>
//               <input
//                 id="pdf-upload"
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleNewPdfChange}
//               />
//             </div>
//           </div>
//           <div className="add-modal-footer">
//             <button onClick={handleAddNew} className="add-button">
//               Add
//             </button>
//           </div>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={editModalIsOpen}
//         onRequestClose={closeEditModal}
//         contentLabel="Edit Advisory"
//         className="modal-content"
//         overlayClassName="modal-overlay"
//       >
//         <div className="modal-header">
//           <h2>Edit Advisory</h2>
//           <button className="close-btn" onClick={closeEditModal}>
//             <FaTimes />
//           </button>
//         </div>
//         <div className="modal-body">
//           {editImagePreview && (
//             <div className="edit-image-preview">
//               <img className='mb-4' style={{width:"400px"}} src={editImagePreview} alt="Preview" />
//             </div>
//           )}
//           <div className="file-input">
//             <label htmlFor="image-upload">
//               <FaUpload className="upload-icon" />
//               Upload New Image
//               <input
//                 id="image-upload"
//                 type="file"
//                 accept="image/*"
//                 onChange={handleEditImageChange}
//               />
//             </label>
//             {editImageName && <span className="file-name">{editImageName}</span>}
//           </div>
//           <div className="file-input">
//             <label htmlFor="pdf-upload">
//               <FaUpload className="upload-icon" />
//               Upload New PDF
//               <input
//                 id="pdf-upload"
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleEditPdfChange}
//               />
//             </label>
//             {editPdfName && <span className="file-name">{editPdfName}</span>}
//           </div>
//           <div className="pdf-link">
//             <a href={selectedPdf.path} target="_blank" rel="noopener noreferrer">View Old PDF</a>
//           </div>
//           <button className="save-btn" onClick={handleSaveChanges}>
//             Save Changes
//           </button>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default Pdf;



import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { PiFilePdfDuotone } from "react-icons/pi";
import { FaEdit, FaPlus, FaTrash, FaUpload, FaTimes } from 'react-icons/fa';
import "./PdfAntiragging.css";
import { Base_Url } from './config';
import { MdClose } from "react-icons/md";
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import { useSelector } from 'react-redux';

Modal.setAppElement('#root');

function Pdf() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState('');
  const [selectedPdf, setSelectedPdf] = useState({});
  const [pdfPath, setPdfPath] = useState('');
  const [advisory, setAdvisory] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [editImagePreview, setEditImagePreview] = useState(null);
  const [newPdf, setNewPdf] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [editPdf, setEditPdf] = useState(null);
  const [newImageName, setNewImageName] = useState('');
  const [newPdfName, setNewPdfName] = useState('');
  const [editImageName, setEditImageName] = useState('');
  const [editPdfName, setEditPdfName] = useState('');

  const userExists = useSelector((state) => state.user.userExists);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const openModal = (pdfPath) => {
    setPdfFile(pdfPath);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setPdfFile('');
  };

  const openEditModal = (e, pdf) => {
    e.stopPropagation();
    setSelectedPdf(pdf);
    setPdfPath(pdf.path);
    setEditImage(null);
    setEditPdf(null);
    setEditImagePreview(pdf.src); // Set existing image as preview on modal open
    setEditImageName(''); // Reset edit image name
    setEditPdfName(''); // Reset edit PDF name
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedPdf({});
  };

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
    setEditImage(file); // Save the file object in state
    setEditImagePreview(URL.createObjectURL(file)); // Generate a preview URL
    setEditImageName(file.name); // Set the edit image name
  };

  const handleEditPdfChange = (e) => {
    const file = e.target.files[0];
    setEditPdf(file); // Save the file object in state
    setEditPdfName(file.name); // Set the edit PDF name
  };

  const handleSaveChanges = () => {
    const formData = new FormData();
    if (editImage) formData.append('image', editImage);
    if (editPdf) formData.append('pdf', editPdf);

    axios.put(`${Base_Url}/update-anticommitte/${selectedPdf.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        closeEditModal();
        fetchAdvisory();
      })
      .catch(error => {
        console.error('Error updating advisory:', error);
      });
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setNewImage(null);
    setNewPdf(null);
    setNewImageName(''); // Reset new image name
    setNewPdfName(''); // Reset new PDF name
  };

  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setNewImageName(file.name); // Set the new image name
  };

  const handleNewPdfChange = (e) => {
    const file = e.target.files[0];
    setNewPdf(file);
    setNewPdfName(file.name); // Set the new PDF name
  };

  const handleAddNew = () => {
    const formData = new FormData();
    if (newImage) formData.append('image', newImage);
    if (newPdf) formData.append('pdf', newPdf);

    fetch(`${Base_Url}/create-anticommitte`, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.ok) {
          closeAddModal();
          setNewImage(null);
          setNewPdf(null);
          fetchAdvisory()
        } else {
          console.error('Failed to upload PDF and image');
        }
      })
      .catch(error => {
        console.error('Error uploading PDF and image:', error);
      });
  };

  const fetchAdvisory = async () => {
    try {
      const response = await axios.get(`${Base_Url}/anticommittes`);
      setAdvisory(response.data)
    } catch (error) {
      console.error('Error fetching advisories:', error);
    }
  };

  useEffect(() => {
    fetchAdvisory();
  }, []);

  const handleDeleteAdvisory = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this advisory?');
    if (confirmed) {
      try {
        await axios.delete(`${Base_Url}/anticommitte/${id}`);
        fetchAdvisory();
      } catch (error) {
        console.error('Error deleting advisory:', error);
      }
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="pdf mt-5">
      <div className="h1 headingSpan mb-4 text-center text-dark">
        <hr />
        <span style={{ color: "#c21717" }}>ANTI-RAGGING</span>{" "}
        {/* <span style={{ color: "#c21717" }}>@</span> */}
        <span>COMMITTEE</span>
        {
          userExists ? <span className='ml-2 '>
            <FaPlus color='#c21717' className='mb-1' onClick={openAddModal} style={{ cursor: 'pointer' }} />
          </span> : ""
        }
        <hr />
        <p></p>
      </div>

      <div className="inception-images mb-5" style={{ height: "650px", cursor: "pointer" }}>
        {advisory && advisory.length > 0 ? (
          advisory.map((elem) => (
            <div key={elem.id} className="image-container" onClick={() => openModal(`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`)}>
              <iframe
                src={`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`}
                width="100%"
                height="100%"
                title={`PDF Viewer - ${elem.id}`}
              />
              {userExists && (
                <div className="action-buttons">
                  {/* <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id })}> */}
                  <button className="edit-button" onClick={(e) => openEditModal(e, { id: elem.id , path:`${Base_Url}/${elem.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`})}>
                    <FaEdit />
                  </button>
                  <button className="delete-button" onClick={(e) => { e.stopPropagation(); handleDeleteAdvisory(elem.id) }}>
                    <FaTrash />
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="no-data-container">
            <div className="no-data-message">
              To be announced soon...
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel="PDF Modal"
        className="pdf-modal dark-mode"
        overlayClassName="pdf-modal-overlay dark-overlay"
      >
        <button onClick={closeModal} className="close-button dark-mode">Close</button>
        {pdfFile && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </Modal>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        contentLabel="Add PDF Modal"
        className="add-modal-container"
        overlayClassName="add-modal-overlay"
      >
        <div className="add-modal">
          <div className="add-modal-header">
            <h2>Add New PDF</h2>
            <button onClick={closeAddModal} className="close-button">
              <MdClose />
            </button>
          </div>
          <div className="add-modal-body">
            {/* <div className="file-input">
              <label htmlFor="image-upload" className="file-input-label">
                <FaUpload className="upload-icon" />
                {newImageName ? newImageName : "Upload Image"}
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                onChange={handleNewImageChange}
              />
            </div> */}
            <div className="file-input">
              <label htmlFor="pdf-upload" className="file-input-label">
                <FaUpload className="upload-icon" />
                {newPdfName ? newPdfName : "Upload PDF"}
              </label>
              <input
                id="pdf-upload"
                type="file"
                accept="application/pdf"
                onChange={handleNewPdfChange}
              />
            </div>
          </div>
          <div className="add-modal-footer">
            <button onClick={handleAddNew} className="add-button">
              Add
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Advisory"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <h2>Edit Advisory</h2>
          <button className="close-btn" onClick={closeEditModal}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-body">
          {editImagePreview && (
            <div className="edit-image-preview">
              <img className='mb-4' style={{ width: "400px" }} src={editImagePreview} alt="Preview" />
            </div>
          )}
          {/* <div className="file-input">
            <label htmlFor="image-upload">
              <FaUpload className="upload-icon" />
              Upload New Image
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                onChange={handleEditImageChange}
              />
            </label>
            {editImageName && <span className="file-name">{editImageName}</span>}
          </div> */}
          <div className="file-input">
            <label htmlFor="pdf-upload">
              <FaUpload className="upload-icon" />
              Upload New PDF
              <input
                id="pdf-upload"
                type="file"
                accept="application/pdf"
                onChange={handleEditPdfChange}
              />
            </label>
            {editPdfName && <span className="file-name">{editPdfName}</span>}
          </div>
          <div className="pdf-link">
            <a href={selectedPdf.path} target="_blank" rel="noopener noreferrer">View Old PDF</a>
          </div>
          <button className="save-btn" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Pdf;


