import React, { useEffect, useState } from 'react';
import './Login.css';
import img1 from "../Assests/iitddoslogo.png"
import { useLocation, useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import ChangePasswordModal from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import axios from 'axios';
import { Base_Url} from "./config";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showForgotModal, setShowForgotModal] = useState(false);
  
    const handleShowChangePasswordModal = () => {
      setShowChangePasswordModal(true);
    };
  
    const handleCloseChangePasswordModal = () => {
      setShowChangePasswordModal(false);
    };
    const handleCloseForgotModal = () => {
      setShowForgotModal(false);
    };
    const handleShowForgotModal = () => {
      setShowForgotModal(true);
    };

  const navigateTo = useNavigate();
  const handleLogin = async() => {
      // const handleLogin = async (e) => {
        // e.preventDefault();
    
        // console.log('Attempting login...'); // Add this line
    
        try {
          const response = await axios.post(`${Base_Url}/admin-login`, {
            email: username,
            password: password,
          });
    
          // console.log('Login response:', response.data); // Add this line
    
          if (response.data.success) {
            // console.log('Login successful!'); // Add this line
            toast.success('Login Successfully!');
            localStorage.setItem("userId", response.data.user.id.toString());
            localStorage.setItem("login", response.data.success.toString());
    
            setTimeout(() => {
              // Successful login, redirect to /
              navigateTo('/');
            }, 1000);
    
          } else {
            // Invalid credentials
            // console.log('Invalid credentials');
            toast.error('Invalid credentials');
          }
        } catch (error) {
          // console.error('Error during login:', error);
          toast.error('Invalid credentials');
        }
      };
    
  // };
  const location = useLocation();

  // console.log(location.pathname)

  useEffect(() => {
    if (location.pathname === "/manage-dos-website") {
      handleLoginWithIITD();
    }
  }, [location.pathname]);
  

const handleLoginWithIITD =()=>{
  // window.location.href="https://oauth.iitd.ac.in/login.php?response_type=code&client_id=yfXGWAysgfgSOYmyT0xBGY7cKIRrv7OZ&state=xyz"
  
  // window.location.href="https://dos.iitd.ac.in/backend/a.php?response_type=code&client_id=yfXGWAysgfgSOYmyT0xBGY7cKIRrv7OZ&state=xyz"

  // window.location.href="api/admin-login/iitd"
  window.location.href=`${Base_Url}/admin-login/iitd`
  // window.location.href="https://dos.iitd.ac.in/api/admin-login/iitd"
  // window.location.href="https://oauth.iitd.ac.in/login.php?response_type=code&state=xyz&client_id=PmQgWttSKfN6MklT1kaewjU8zJsXY6lJ&state=xyz"
  
}

  return (
    <>
    <div className="grid">
      <div className="order__left centered">
        <div className="form">
          <div className="logo">
            <div className="logo">
              <img
                src={img1}
                className='img-fluid'
                alt="Your Logo"
              />
            </div>
          </div>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Email"
            className='inputLogin'
            autoComplete="off"
            value={username}
            onChange={handleUsernameChange}
          />
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            className='inputLogin'
            autoComplete="off"
            value={password}
            onChange={handlePasswordChange}
          />

          <div className="justify__space_between justify-content-center loginwithiitd">
            {/* <span>
              <label
                htmlFor="remember_me"
                className="forgot__password remember_me"
                onClick={handleShowChangePasswordModal}
              >
                Change Password
              </label>
              <br />
            </span>
            <a href="#" className="forgot__password" onClick={handleShowForgotModal}>
              Forgot Password?
            </a> */}
            {/* <a href="${Base_Url}/login/iitd">
            <label
                htmlFor="remember_me"
                className="forgot__password remember_me"
                // onClick={handleLoginWithIITD}
                style={{color:"#a50b02"}}
              >
                Login with IITD
              </label>
    </a> */}
         <label
                htmlFor="remember_me"
                className="forgot__password remember_me loginbtn"
                onClick={handleLoginWithIITD}
                style={{color:"#a50b02"}}
              >
                Login with IITD
              </label>    
          </div>

          <button
            type="button"
            className="login__button"
            onClick={handleLogin}
          >
            Login
          </button>

          <div className="signup">
            <label className="remember_me">Don't have an account?</label>
            <a
              href="#"
              className="forgot__password"
              style={{ textDecoration: 'none' }}
            >
              <strong style={{ color: '#a50b02' }}>Sign up for free.</strong>
            </a>
          </div>
        </div>
      </div>

      <div className="order__right centered no__overflow">
        <img
          className="img img-fluid h-100"
          src="https://home.iitd.ac.in/images/for-faculty/camp8.jpg"
          alt="picture"
          style={{width:"100%"}}
        />
      </div>
    </div>
      <ToastContainer /> 
      <ChangePasswordModal loginId={username} show={showChangePasswordModal} handleClose={handleCloseChangePasswordModal} />
      <ForgotPassword  show={showForgotModal} handleClose={handleCloseForgotModal} />
      </>
  );
};

export default Login;

