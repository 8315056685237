import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Main from "./Components/Main";
import Login from "./Components/Login";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FaArrowCircleUp } from "react-icons/fa";
import "./style2.css";
import About from "./Components/About";
import Board from "./Components/Board";
import AntiRagging from "./Components/AntiRagging";
import FundsMedicalInsurance from "./Components/FundsMedicalInsurance";
import ManageUsers from "./Components/ManageUsers";
import ManageChairperson from "./Components/ManageChairperson";
import NotFound from "./Components/404";


function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 160) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Check if the current page is 'login'
  const isLoginPage = location.pathname === '/login';
  // Check if the current route is 'antiRagging'
  const isAntiRaggingRoute = location.pathname === '/antiRagging';

  return (
    <div className={`container-fluid p-0 p-lg-0 position-relative ${isAntiRaggingRoute ? 'anti-ragging-route' : ''}`}>
      <div id="top">
        {/* Conditionally render Header based on the current page */}
        {!isLoginPage && <Header />}
      </div>

      {/* <div style={{ background: "#f7f7f7", minHeight: (window.innerWidth <= 576 || window.innerWidth <= 768) && isAntiRaggingRoute ? "400px" : "500px" }}> */}
      <div style={{ background: "#f7f7f7", minHeight: "700px"}}>

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/board" element={<Board />} />
          <Route path="/antiragging" element={<AntiRagging />} />
          <Route path="/manage-dos-website" element={<Login />} />
          <Route path="/manage-users" element={<ManageUsers />} />
          <Route path="/funds" element={<FundsMedicalInsurance />} />
          <Route path="/ChairpersonDetails" element={<ManageChairperson />} />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </div>

      <div>
        {/* Conditionally render Footer based on the current page */}
        {!isLoginPage && <Footer />}
      </div>

      {/* Scroll to Top button */}
      {!isLoginPage && showScrollButton && (
        <div className="scroll-to-top-icons bg-white">
          <FaArrowCircleUp
            className="scrolltop"
            onClick={scrollToTop}
          />
        </div>
      )}
    </div>
  );
}

export default App;
