import React, { useEffect, useRef, useState } from "react";
import "./AntiRag.css"; // Import the new CSS file
import { AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import axios from "axios";
import { FaEdit, FaTrash, FaSave, FaPlus, FaTrashAlt } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { selectUserExists } from "../Redux/userSlice";
import { useLocation } from "react-router-dom";
import { Base_Url } from "./config";
import Pdf from "./PdfAntiragging";
import PdfAntiCommitte from "./PdfAntiCommitte"
import PdfAntiSquad from "./PdfAntiSquad"
import SideDetails from "./SideDetails";



const Sidebar = ({ isOpen, toggleSidebar }) => {

  const [antiPolicy, setAntiPolicy] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [data, setData] = useState([]);


  const [antiPolicyName, setAntiPolicyName] = useState("")
  const [antiPolicyPdf, setAntiPolicyPdf] = useState("")
  const [currentAntiPolicyId, setCurrentAntiPolicyId] = useState("")
  const [chairpersons, setChairpersons] = useState();

  const userExists = useSelector((state) => state.user.userExists);
  const [isEdit, setEdit] = useState(userExists)

  // console.log(userExists)
  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log(userDataRedux,"userData")



  const handleFileChange = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const addPolicy = () => {
    const formData = new FormData();
    formData.append('pdf', pdfFile);
    formData.append('pdf_name', antiPolicy);
    formData.append('created_by', userDataRedux?.user_id);

    axios.post(`${Base_Url}/uploadpdf`, formData)
      .then((response) => {
        toast.success('Policy Created Successful');
        fetchData()
        closeButtonRef.current.click();
        // Handle success, e.g., show a success message
      })
      .catch((error) => {
        // console.error('Error uploading PDF:', error);
        // Handle error, e.g., show an error message
      });
  };




  useEffect(() => {
    // Fetch data from your API when the component mounts
    fetchData();
  }, []);

  const fetchData = () => {
    // Fetch data from your API endpoint
    fetch(`${Base_Url}/pdfs`)
      .then(response => response.json())
      .then(data => {
        // Once data is fetched, update the state
        setData(data);
      })
    // .catch(error => console.error('Error fetching data:', error));
  };
  // anti-policy-soft-delete

  const markAsDeleted = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Policy?");

    if (confirmDelete) {
      // Ensure userDataRedux is not null or undefined and has at least one element
      if (userDataRedux && !userDataRedux == "") {
        axios.put(`${Base_Url}/anti-policy-soft-delete/${id}`, {
          deleted_by: userDataRedux?.user_id
        })
          .then(response => {
            toast.success("Deleted successfully");
            fetchData();
            // Optionally, you can update the UI or perform any other actions after marking the member as deleted
          })
          .catch(error => {
            // console.error('Error marking member as deleted:', error);
            // Handle error, e.g., show an error message to the user
          });
      } else {
        // console.error('userDataRedux is null, undefined, or empty');
        // Handle the case where userDataRedux is not properly initialized or empty
      }
    }
  };


  const handleEdit = async (obj) => {
    // console.log(obj)
    setCurrentAntiPolicyId(obj?.id)

    try {
      const response = await axios.get(`${Base_Url}/pdfs/${obj.id}`);
      setAntiPolicyPdf(response.data.pdf_path)
      setAntiPolicyName(response.data.pdf_name)
    } catch (error) {
      // console.error('Error fetching PDF data:', error);
    }
  }



  const handleFileChangeEdit = (e) => {
    const file = e.target.files[0];
    // setFileName(file)
    // setSelectedBoard(prevSelectedBoard => ({
    //   ...prevSelectedBoard,
    //   board_constitution_pdf: file
    // }));

    setAntiPolicyPdf(file)
    // console.log(file)
  };

  // console.log(antiPolicyName)

  const updatePolicy = async () => {
    try {
      const formData = new FormData();
      formData.append('pdf', antiPolicyPdf); // Make sure antiPolicyPdf is not null
      formData.append('pdf_name', antiPolicyName); // Make sure antiPolicyName is not null
      formData.append('updated_by', userDataRedux?.user_id);// Make sure antiPolicyName is not null

      const response = await axios.put(
        `${Base_Url}/updatePolicy/${currentAntiPolicyId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      // console.log(response.data); // Handle response data as needed
      toast.success('Updated successfully');
      setCurrentAntiPolicyId("");
      setAntiPolicyPdf("");
      setAntiPolicyName("");
      closeButtonRef2.current.click();
      fetchData();
    } catch (error) {
      // console.error('Error updating PDF file:', error);
      toast.error('Error updating PDF file. Please try again later.');
    }
  };


  const closeButtonRef = useRef(null);
  const closeButtonRef2 = useRef(null);






  const fetchDataChairperson = async () => {
    try {
      const response = await axios.get(`${Base_Url}/antiragging_chairperson`);
      setChairpersons(response.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDataChairperson();
  }, []);


  // console.log(chairpersons)
  return (

    <>
      {/* 1st modal  */}
      <div className="modal fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
              <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Add Anti-Ragging Policy</h5>
              <button className="text-white close" type="button" ref={closeButtonRef} data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <form>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="name">Anti Ragging Policy :</label>
                            <input
                              type="text"
                              className="p-2 form-control mt-2"
                              id="name"
                              name="antiPolicy"
                              placeholder="Anti Ragging Policy"
                              value={antiPolicy}
                              onChange={(e) => setAntiPolicy(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="image">Upload Policy:</label>
                            <input
                              type="file"
                              name="pdf"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              className="form-control-file mt-2"
                              id="constitutionInput" // Unique ID
                            />
                            <button
                              type="button"
                              className="btn btn-secondary mt-2"
                              onClick={() => document.getElementById('constitutionInput').click()}
                            >
                              {pdfFile?.name ? pdfFile?.name : "Upload"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-success mt-4 btn-block w-25"
                        onClick={addPolicy}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 2nd modal  */}
      <div className="modal fade" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center text-white" style={{ background: "#C21717", padding: "15px" }}>
              <h5 className="modal-title" id="exampleModalLabel" style={{ marginLeft: "auto" }}>Edit Anti-Ragging Policy</h5>
              <button className="text-white close" type="button" ref={closeButtonRef2} data-dismiss="modal" aria-label="Close" style={{ marginRight: "-10px", marginTop: "-10px" }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <form>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="name">Anti Ragging Policy :</label>
                            <input
                              type="text"
                              className="p-2 form-control mt-2"
                              id="name"
                              name="board_name"
                              placeholder="Enter name"
                              value={antiPolicyName}
                              onChange={(e) => setAntiPolicyName(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="mb-0" htmlFor="image">Anti Ragging Policy Pdf:</label>
                            <input
                              type="file"
                              name="board_constitution_pdf"
                              onChange={handleFileChangeEdit}
                              style={{ display: "none" }}
                              className="form-control-file mt-2"
                              id="constitutionInput2" // Unique ID
                            />
                            <button type="button" className="btn btn-secondary mt-2" onClick={() => document.getElementById('constitutionInput2').click()}>
                              {antiPolicyPdf?.name ? antiPolicyPdf?.name : antiPolicyPdf}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="button" className="btn btn-success mt-4 btn-block w-25"
                        onClick={updatePolicy}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* modal end */}

      <div className={`sidebar-wrapper ${isOpen ? "open" : ""}`}>
        <div className="sd-header">
          <h4
            className="mb-0 pl-lg-1"
            style={{ fontSize: "15px", width: "80%", fontWeight: "700", color: "white" }}
          >
            Anti-Ragging Details policy of IIT Delhi can be found in the following
            documents:-
          </h4>
          <div style={{ cursor: "pointer" }} onClick={toggleSidebar}>
            <AiOutlineClose style={{ fontSize: "16px", fontWeight: "bolder" }} />
          </div>
        </div>
        <div className="sd-body">
          <ul>

            {
              data && data.length > 0 && data?.map((document) => {
                if (document.is_deleted) {
                  return null; // Skip rendering soft-deleted members
                }
                return (
                  <>
                    <li key={document.id}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <a className="sd-link" href={`${Base_Url}/${document?.pdf_path.replace(/\\/g, '/').replace('uploads/', '')}`} target="_blank" rel="noopener noreferrer">
                          {document?.pdf_name}
                        </a>
                        {/* Edit icon */}

                        {
                          userExists ? <>
                            <FaEdit className="text-success" style={{ marginLeft: '10px', cursor: 'pointer' }}
                              type="button"
                              onClick={() => handleEdit(document)}
                              data-toggle="modal" data-target="#exampleModal4"
                            // onClick={() => handleEdit(document.id)} 
                            />
                            {/* Delete icon */}
                            <FaTrashAlt className="text-danger" style={{ marginLeft: '10px', fontSize: "13px", cursor: 'pointer' }}
                              onClick={() => markAsDeleted(document.id)}
                            />
                          </> : ""
                        }

                      </div>
                    </li>

                  </>
                )
              })
            }
            {chairpersons && chairpersons?.map((chairperson, index) => {
              try {
                return (
                  <>
                    {/* <li key={index}> */}
                    {/* <a className="sd-link" href={`mailto:${chairperson?.contact_link}`}>Contact Chairperson</a> */}
                    {/* </li> */}
                  </>
                );
              } catch (error) {
                // console.error('Error rendering chairperson:', error);
                return (
                  <li key={index}>
                    <span>Error rendering chairperson details</span>
                  </li>
                );
              }
            })}


            {
              userExists ? <button type="button" data-toggle="modal" data-target="#exampleModal3" className="btn btn-success mb-5 btn-sm mt-3 p-2">
                Add Policy
              </button> : ""
            }



            {/* Additional list items */}
          </ul>
        </div>
      </div>
    </>
  );
};

const AntiRagging = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  const userDataRedux = useSelector((state) => state.user.userData);
  // console.log( userDataRedux?.user_id,"userData")

  const [data, setData] = useState([]);
  const [editBool, setEditBool] = useState(false);

  const userExists = useSelector(selectUserExists);
  // console.log(userExists, 'userexists');

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Base_Url}/admin-iitddosall`);
      // console.log(response); // Replace with your API endpoint
      setData(response?.data); // Update the state with the fetched data

    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const handleEditEmail = (id) => {
    const updatedChairpersons = chairpersons.map((chairperson) => {
      if (chairperson.id === id) {
        const newEmail = prompt("Enter the new email address:");
        if (newEmail) {
          // Update the email in the state immediately
          return { ...chairperson, email: newEmail };
        }
      }
      return chairperson;
    });
    fetchDataChairperson()
    // setChairpersons(updatedChairpersons);

    // Send API request to update the email in the database
    axios.put(`${Base_Url}/antiragging_chairperson/${id}`, { email: updatedChairpersons.find(cp => cp.id === id).email })
      .then(response => {
        toast.success(response.data.message);
        fetchDataChairperson()
      })
      .catch(error => {
        // console.error('Error updating email:', error);
        // You can handle errors here, such as displaying a message to the user
      });
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this member?");
    if (confirmDelete) {
      try {
        const deletedBy = userDataRedux?.user_id; // Assuming you have access to the user's ID
        const response = await axios.put(`${Base_Url}/admin-softdelete/${id}`, {
          deleted_by: deletedBy
        });

        // console.log(response.data);
        toast.success('Contact Soft Deleted successfully!');
        fetchData();
        // Handle success, e.g., show a success message to the user
      } catch (error) {
        // console.error(error);
        toast.error('Error deleting contact. Please try again.');
        // Handle error, e.g., show an error message to the user
      }
    }
  };



  const [editedData, setEditedData] = useState({
    id: null,
    name: "",
    designation: "",
    contact: "",
  });

  const handleEdit = (id) => {
    const editedItem = data.find((item) => item.id === id);
    setEditedData({
      id: editedItem.id,
      name: editedItem.name,
      designation: editedItem.designation,
      contact: editedItem.contact,
    });
    setEditBool(true);
  };


  // console.log(editedData)
  const handleSave = async () => {
    try {
      if (editedData.id) {
        // Existing data, update
        const updatedData = { ...editedData, updated_by: userDataRedux?.user_id };
        await axios.put(`${Base_Url}/admin-iitddosput/${editedData.id}`, updatedData);
        // console.log('Data updated successfully');
        toast.success('Contact updated successfully!');
      } else {
        // New data, add
        const newData = { ...editedData, created_by: userDataRedux?.user_id };
        await axios.post(`${Base_Url}/admin-iitddosadd`, newData);
        // console.log('Data added successfully');
        toast.success('Contact added successfully!');
      }

      setEditBool(false);
      fetchData(); // Refresh the data after updating or adding
    } catch (error) {
      // console.error('Error saving data:', error);
      toast.error('Error saving contact. Please try again.');
    }
  };




  const handleAdd = () => {
    const newContact = {
      name: "",
      designation: "",
      contact: "",
    };

    // setData([...data, newContact]);
    setData([...data, { ...newContact, created_by: userDataRedux?.user_id }]);
    setEditBool(true);
    if (editedData.id) {
      setEditedData({ ...newContact, updated_by: userDataRedux?.user_id });
    }
    else {
      setEditedData({ ...newContact });
    }

    // Set isNewContact to true when adding a new contact
    setIsNewContact(true);
    toast.info('Adding a new contact.');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancel = () => {
    setEditBool(false);
    fetchData();
    toast.info('Editing canceled.');
  };


  const handleDeleteLastAdded = () => {
    const lastAddedId = data[data.length - 1]?.id;

    if (lastAddedId) {
      handleDelete(lastAddedId);
      toast.info('Last added contact deleted.');
    } else {
      setData(data.slice(0, data.length - 1));
      toast.info('Last added contact removed.');
    }
  };
  // console.log(data)


  const accessAction1 = localStorage.getItem("login")
  const accessAction2 = localStorage.getItem("userId")


  // console.log(accessAction1)
  // console.log(accessAction2)


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Attach event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();
  const [isHomeRoute, setIsHomeRoute] = useState(false);
  useEffect(() => {
    // Check if the current route is the home route
    setIsHomeRoute(location.pathname === '/antiRagging');
  }, [location.pathname]);



  const [chairpersons, setChairpersons] = useState();
  const fetchDataChairperson = async () => {
    try {
      const response = await axios.get(`${Base_Url}/antiragging_chairperson`); // Assuming your server is running on the same host/port
      setChairpersons(response.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDataChairperson();
    fetchContent();
  }, []);

  const [content, setContent] = useState(false);
  const fetchContent = async () => {
    try {
      const response = await axios.get(`${Base_Url}/chairperson-content`);


      setContent(response?.data);

    } catch (error) {
      // console.error('Error fetching content:', error);
    }
  };

  return (
    <>

      <style>
        {`
        /* Only apply the styles when on the home route */
        ${isHomeRoute && `
          ::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }
          
          ::-webkit-scrollbar-thumb {
            background-color: #888;
          }
          
          ::-webkit-scrollbar-corner {
            background-color: #f1f1f1;
          }

          ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
        `}
      `}
      </style>

      <div>
        <div className="social">
          <a href="#">
            <div class="social-btn color-instagram abc" onClick={toggleSidebar}>
              <div class="icons8-instagram"></div>
              <p className="order-1 google-font d-flex margin-instagram mb-0 text-white">
                {windowWidth <= 767 ? '' : 'More Details'}
                <span className="d-flex align-items-center">
                  <BsFillInfoCircleFill
                    className="mb-1 d-flex "
                    style={{ fontSize: "20px" }}
                  />
                </span>
              </p>

            </div>
          </a>
        </div>
        <div className="d-flex  main-container-anti">
          <div class="box-wrap w-75">
            <div class="table-wrap">
              <div class="h1  mb-4  mt-5 text-center text-dark">
                <span style={{ color: "#c21717" }}> ANTI-RAGGING</span>{" "}
                {/* <span style={{ color: "#c21717" }}>@ </span> */}
                <span>IIT DELHI</span>
                <p className="mt-5 mb-4">
                  {" "}

                  <h6 className="text-dark emergencycontact mt-4" style={{ fontWeight: "bolder" }}> Emergency Contact Details -</h6>
                </p>
              </div>
              <table>
                <thead>
                  <tr>
                    {windowWidth >= 767 ? <th className="thanti">sr no.</th> : ""}

                    <th className="thanti">Name</th>
                    <th className="thanti">Designation</th>
                    <th className="thanti">Contact Number</th>
                    {/* {
                  accessAction1 && accessAction2 ?  <th className="thanti">Actions</th> :""
                } */}
                    {
                      userExists ? <th className="thanti">Actions</th> : ""
                    }

                  </tr>
                </thead>
                <tbody>
                  {data
                    ?.filter((elem) => !elem.is_deleted) // Filter out items with softDelete true
                    .map((elem, index) => (
                      <tr key={index}>
                        {windowWidth >= 767 ? <td className="tdanti">{index + 1}</td> : ""}

                        <td className="tdanti">
                          {editBool && editedData.id === elem.id ? (
                            <input
                              style={{ border: "none" }}
                              type="text"
                              name="name"
                              value={editedData.name}
                              onChange={handleInputChange}
                              className="inputchnage"
                              autoFocus
                            />
                          ) : (
                            elem.name
                          )}
                        </td>
                        <td className="tdanti">
                          {editBool && editedData.id === elem.id ? (
                            <input
                              style={{ border: "none" }}
                              type="text"
                              name="designation"
                              value={editedData.designation}
                              onChange={handleInputChange}
                              className="inputchnage"
                            />
                          ) : (
                            elem.designation
                          )}
                        </td>
                        <td className="tdanti">
                          {editBool && editedData.id === elem.id ? (
                            <input
                              style={{ border: "none" }}
                              type="text"
                              name="contact"
                              value={editedData.contact}
                              onChange={handleInputChange}
                              className="inputchnage"
                            />
                          ) : (
                            elem.contact
                          )}
                        </td>
                        {
                          //  accessAction1 && accessAction2  ? <td className="tdanti action-icons" >
                          userExists ? <td className="tdanti action-icons" >
                            {!editBool && (
                              <FaEdit
                                className="action-icon me-2"
                                onClick={() => handleEdit(elem.id)}
                              />
                            )}
                            {editBool && editedData.id === elem.id && (
                              <>
                                <FaSave
                                  className="action-icon me-2 mt-1"
                                  onClick={handleSave}
                                />
                                <AiOutlineClose
                                  className="action-icon mt-1"
                                  onClick={handleCancel}
                                />
                              </>
                            )}
                            {!editBool && (
                              <FaTrash
                                className="action-icon"
                                onClick={() => handleDelete(elem.id)}
                              />
                            )}
                          </td> : ""
                        }

                      </tr>
                    ))}
                </tbody>



              </table>


              <div className="ragging-report-info d-flex justify-content-center mt-5">
                <p className="p-2 " style={{border:"1px solid #c21717", borderRadius:"8px",background:"#c21717" ,color:"white"}}>
                  For any ragging related issues at IITD, please send email to the Chairperson:
                
               
                  {
                    chairpersons && chairpersons?.map((chairperson) => {
                      // console.log(chairperson)
                      return (
                        <>
                         Email: <a className="text-decoration-underline emailchairperson" style={{color:"#fff" , textDecoration:"underline !important"}} href={`mailto:${chairperson?.email}`}>{chairperson?.email}</a>  {
                            userExists ? <FaEdit onClick={() => handleEditEmail(chairperson.id)} color="#c21717 " /> : ""
                          }
                        </>
                      )
                    })
                  }
                </p>


                {content && content?.map((item) => (
                  <div key={item.id} className="d-flex">
                    <div className="mr-2" dangerouslySetInnerHTML={{ __html: item?.content }} />
                    {/* {item.content} */}
                    <FaEdit className="mt-1" color="#c21717"
                    // onClick={() => handleEditClick(item.id)}
                    />
                  </div>
                ))}

              </div>


              {
                userExists && <div className="d-flex justify-content-end align-items-center">
                  <button className="add-contact-btn d-flex" onClick={handleAdd}>
                    <span className="me-2 " style={{ marginRight: "10px" }}>
                      {windowWidth >= 767 ? 'Add Emergency Contact Details' : 'Add Contact'}</span>{" "}
                    <FaCirclePlus className="mb-1" style={{ fontSize: "18px" }} />
                  </button>
                </div>
              }

            </div>
          </div>

          <SideDetails />

        </div>
        <PdfAntiCommitte />
        <PdfAntiSquad />
        <Pdf />
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <ToastContainer />
      </div>
    </>
  );
};

export default AntiRagging;


{/* <table>
            <thead>
              <tr>
                <th>sr no.</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Contact Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tdanti">1</td>
                <td>Prof. Sampa Saha</td>
                <td>Chairperson - Proctorial Team</td>
                <td>9953659882</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Prof. Tapan Kumar Nayak </td>
                <td>Vice Chairperson - Proctorial Team</td>
                <td>9811340921</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Prof. Aditya Mittal</td>
                <td>Dean of Students</td>
                <td>9899448073</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Prof. B. K. Panigrahi</td>
                <td>Associate Dean (Hostel Management)</td>
                <td>7290008571</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Prof. Vivekanandan Perumal</td>
                <td>Associate Dean (Student Events)</td>
                <td>7827615550</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Prof. Tanusree Chakraborty</td>
                <td>Associate Dean (Student Welfare)</td>
                <td>8447284906</td>
              </tr>
            </tbody>
          </table> */}
{/* <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/sc-ord07.pdf" target="_blank" rel="noopener noreferrer">Supreme Court Order (2007)</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/sc-ord09.pdf" target="_blank" rel="noopener noreferrer">Judgment of Supreme Court (2009)</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/menace.pdf" target="_blank" rel="noopener noreferrer">Raghavan Committee Report</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/ugc.pdf" target="_blank" rel="noopener noreferrer">UGC Regulations</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/off-ord.pdf" target="_blank" rel="noopener noreferrer">IIT Delhi Order</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/Fresher%E2%80%99s%20interaction-%20Do%E2%80%99s%20and%20don%E2%80%99ts.pdf" target="_blank" rel="noopener noreferrer">Freshers’ Interaction: Do’s and Don’ts</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/Fresher%E2%80%99s%20interaction-%20Some%20points%20to%20remember.pdf" target="_blank" rel="noopener noreferrer">Freshers’ Interaction: Some Points</a>
            </li>
            <li>
              <a className="sd-link" href="https://dos.iitd.ac.in/anti-ragging/Some%20points%20to%20remember%20about%20ragging.pdf" target="_blank" rel="noopener noreferrer">Some Points to Remember</a>
            </li> */}