import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_Url} from "./config";

const ChangePasswordModal = ({ show, handleClose,loginId }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const handleSaveChanges = async () => {
    try {
      // Check if new password and confirm new password match
      if (newPassword !== confirmNewPassword) {
        // console.error('New password and confirm new password do not match');
        // You might want to display an error message to the user
        return;
      }

      // Make an Axios request to your backend API
      const response = await axios.put('/api/admin-changepassword', {
        email: email, // Replace with the user's email or get it from your authentication state
        currentPassword,
        newPassword,
      });

      // Handle the response from the server
      if (response.data.success) {
        // Password changed successfully
        // console.log('Password changed successfully');
        toast.success('Password changed successfully');


        // You might want to display a success message to the user
      } else {
        // Handle errors from the server
        // console.error('Error changing password:', response.data.message);
        toast.error('Credentials Failed !');
        // You might want to display an error message to the user
      }

      // Close the modal after handling password change
      handleClose();
    } catch (error) {
      // console.error('Error during password change:', error);
      toast.error('Credentials Failed !');
      // Handle unexpected errors
      // You might want to display an error message to the user
    }
    setEmail("")
    setNewPassword("")
    setConfirmNewPassword("")
    setCurrentPassword("")

  };
  return (
    <>
    <Modal  show={show} centered onHide={handleClose}>
      <Modal.Header  >
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="currentPassword">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email password"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="currentPassword">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter current password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="confirmNewPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm new password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>

<ToastContainer />

</>
  );
};

export default ChangePasswordModal;
